export enum Language {
  ENGLISH = "en",
  GERMAN = "de",
  // FRENCH = "fr",
  // ARABIC = "ar",
  // SPANISH = "es",
  // ITALIAN = "it",
  // PORTUGUESE = "pt",
  // RUSSIAN = "ru",
  // TURKISH = "tr",
  // CHINESE = "zh",
  // JAPANESE = "ja",
  // KOREAN = "ko",
  // HINDI = "hi",
}
