import { User } from "@firebase/auth";

import {
  collection,
  Firestore,
  addDoc,
  updateDoc,
  getDocs,
  query,
  where,
  doc,
  getFirestore,
} from "firebase/firestore";
import { MyUser } from "@/models/MyUser";
import { Mitgliedschaft } from "@/models/enums/Mitgliedschaft";
import { firebaseApp } from "@/services/firebase.service";

const db: Firestore = getFirestore(firebaseApp);

export const userService = {
  async saveUser(user: User) {
    const myUser: MyUser = this.mapUser(user);
    const addData = async () => {
      try {
        const newDocRef = await addDoc(collection(db, "users"), myUser);
        console.log(
          "Dokument erfolgreich hinzugefügt mit der ID:",
          newDocRef.id
        );
        return myUser;
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    };
    try {
      const usersCollection = collection(db, "users");
      const q = query(usersCollection, where("email", "==", user.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("Benutzer nicht gefunden");
        return await addData();
      } else {
        let foundUser: MyUser | undefined;
        querySnapshot.forEach((doc) => {
          foundUser = doc.data() as MyUser;
        });
        return foundUser;
      }
    } catch (error) {
      console.error("Fehler beim Überprüfen des Benutzers:", error);
    }
  },

  async updateUser(myUser: MyUser): Promise<void> {
    const updateData = async (documentId: string) => {
      try {
        const documentRef = doc(db, "users", documentId);

        const newData = {
          displayName: myUser.displayName,
          email: myUser.email,
          emailVerified: myUser.emailVerified,
          isAnonymous: myUser.isAnonymous,
          admin: myUser.admin,
          mitgliedschaft: myUser.mitgliedschaft,
          expiringDate: myUser.expiringDate,
          lastGamesJoined: myUser.lastGamesJoined,
        };
        return await updateDoc(documentRef, newData);
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Dokuments:", error);
      }
    };
    try {
      const usersCollection = collection(db, "users");
      const q = query(usersCollection, where("email", "==", myUser.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("Benutzer nicht gefunden");
      } else {
        querySnapshot.forEach((doc) => {
          updateData(doc.id);
        });
      }
    } catch (error) {
      console.error("Fehler beim Überprüfen des Benutzers:", error);
    }
  },

  async getUser(uid: string): Promise<MyUser | undefined> {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("uid", "==", uid));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("Benutzer nicht gefunden");
      return undefined;
    } else {
      let foundUser: MyUser | undefined;
      querySnapshot.forEach((doc) => {
        foundUser = doc.data() as MyUser;
      });
      return foundUser;
    }
  },

  mapUser(user: User, name?: string): MyUser {
    return {
      displayName:
        user.displayName ?? name ?? `User ${user.uid.substring(0, 7)}`,
      uid: user.uid,
      expiringDate: null,
      email: user.email,
      emailVerified: user.emailVerified,
      admin: false,
      inGame: false,
      askedToGameToJoin: "",
      leftGame: false,
      showGhostWords: true,
      isAnonymous: user.isAnonymous,
      mitgliedschaft: Mitgliedschaft.KEINE,
      lastGamesJoined: [],
    };
  },
};
