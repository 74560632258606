export default {
  "cardsI18n": {
    "reduceVote1-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce"])},
    "reduceVote05-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce"])},
    "powerVote1-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase"])},
    "powerVote05-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase"])},
    "showCard-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "dayPfaffer": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastor"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Pastor may once a day view the card of a living player."])}
    },
    "dayFridhofpfleger": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graveyard Gardener"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Graveyard Gardener may once a day view the card of a dead player."])}
    },
    "dayLehrerin": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Teacher may reduce the voting rights of a living player by 1 each day. This effect cannot be applied to the same player on two consecutive days."])}
    },
    "dayGhostWhisperer": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost Whisperer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Ghost Whisperer may increase the voting rights of a dead player by 0.5 each day. This effect cannot be applied to the same player on two consecutive days."])}
    },
    "daySmellyGuy": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smelly Guy"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Smelly Guy hasn't showered for months... he stinks so much that the Werewolves won't eat him (he cannot be eaten by the Werewolves)."])}
    },
    "dayFakelMeister": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torch Master"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Torch Master cannot be killed by the village during the vote, as long as all Werewolves are still alive.\n\n If he has the most votes at the end of the vote, he only dies if at least one Werewolf has already died. Otherwise, nobody dies and it becomes night."])}
    },
    "dayBuerger": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizen"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizens can elect a Citizen Representative every day. This representative will have double voting rights for the vote at the end of the day (their voting rights increase by 1). There can only be one Citizen Representative, and this needs to be chosen anew every day."])}
    },
    "daySuendenbock": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scapegoat"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your only ability is that if there's a tie in the voting during the day, instead of the accused, you must die. At least, if you die in this way, you can determine who gets to vote the next day and who doesn't. Note: If all players allowed to vote die at night, there's no voting the following day. After that, everything returns to normal. But you're still dead."])}
    },
    "daySchuetze": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Archer can shoot a player every day. If the player belongs to the Werewolves, they die. If the player belongs to the village, the Archer dies."])}
    },
    "assigeFromUnssed": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unused"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This card is unused and only a placeholder."])}
    },
    "assigeFromALl": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unused"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This card is unused and only a placeholder."])}
    },
    "emptyCardI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This card is empty and intended only as a placeholder."])}
    },
    "dorfbewohnerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villager"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are a normal villager without special abilities. You must try to expose and kill the werewolves. Win together with the village when all werewolves are dead."])}
    },
    "heilerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A wandering healer who visits a village resident every night and protects them, including themselves. They cannot protect the same person twice in a row. Win together with the village when all werewolves are dead."])}
    },
    "seherI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view the card of a living player every night."])}
    },
    "hexeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Witch"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An old witch who has a healing potion and a poison potion, with which she can save the victim of the werewolves or kill a villager of her choice. Win together with the village when all werewolves are dead."])}
    },
    "mrFreezI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr. Freeze"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every night, you must freeze a person (you cannot freeze the same person twice in a row). A frozen person cannot die, vote, or even speak. They are frozen until the next nightfall."])}
    },
    "amorI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupid"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the first night, you choose two players to fall in love with each other. These players are then immortally in love with each other and, if they are werewolf and villager, play together against all. If one of them dies, the other dies too."])}
    },
    "jagerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you die, you can still shoot someone and go to death with them (anyone)."])}
    },
    "trottelI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fool"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every village has a fool. Who would have thought it's you? If the village wants to kill you during the day by voting, they realize at the last moment that you're just the fool and spare you. But your voting power will be reduces by 0.5."])}
    },
    "magdI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmaid"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As soon as someone is killed by voting during the day, you can take on their role before it's revealed. You are then no longer the handmaid but assume that role with all its abilities. One-time abilities of that role are renewed."])}
    },
    "sundenbockI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scapegoat"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your only ability is that if there's a tie in the voting during the day, instead of the accused, you must die. At least, if you die in this way, you can determine who gets to vote the next day and who doesn't. Note: If all players allowed to vote die at night, there's no voting the following day. After that, everything returns to normal. But you're still dead."])}
    },
    "alteI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elder"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The elder survives the first wolf attack and only dies at the second. However, if killed by the village, it loses all its special roles."])}
    },
    "augeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eye"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The eye finds out on the first night who the seer and the seer apprentice are."])}
    },
    "beschworerinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conjurer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The conjurer can deprive a player of their voting rights every night (reduce Vote by 1)."])}
    },
    "chaosstiferinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaos Sower"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chaos sower can decide once at night to sow chaos. If they do, two separate hanging take place the following day."])}
    },
    "drudeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can limit the night target to 3 players once."])}
    },
    "verlasenesMadchenI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned Girl"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The abandoned girl must choose a player to stay with every night, or else it would freeze. However, if that player happens to be a wolf, it gets eaten instead of the regular night victim, without discovering the wolf. It cannot choose the same player two nights in a row."])}
    },
    "schwesterI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sister"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The two sisters know each other. They know they're sisters."])}
    },
    "bruderI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brother"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The three brothers know each other and know that none of them is a wolf."])}
    },
    "kreuterhaxeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herbal Witch"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The herbal witch learns the Werewolf victim every night and can heal it once, as long as she hasn't used her healing potion yet."])}
    },
    "lykantropinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lycanthrope"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lycanthrope is seen as a werewolf by the seer and their apprentice."])}
    },
    "martyrerinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martyr"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of the night, the martyr learns the names of all dying players and can sacrifice herself for one of them, who then survives."])}
    },
    "meuchelmorderI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assassin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The assassin can kill a player of their choice once at night during the game."])}
    },
    "auraseherinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aura Seer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The aura seer can check a player's aura and learns which group the player belongs to for winning purposes."])}
    },
    "neugierigerNachbarI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curious Neighbor"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The curious neighbor learns the role of the player to their left on the first night."])}
    },
    "phantomI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phantom"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Phantom can reveal itself to any player every night (through the game master)."])}
    },
    "priesterI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priest"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The priest can bless another player once at night. A blessed player can never die at night again (except due to lovesickness). Choose wisely whom to bless."])}
    },
    "prinzI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prince"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the prince is hanged, he survives the first attempt, and his role is revealed."])}
    },
    "rabeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raven"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The raven can send a threatening letter to any player every night. That player starts the next voting with two votes against them."])}
    },
    "rotkappchenI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Little Red Riding Hood"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As long as the hunter is alive, Little Red Riding Hood cannot be eaten by the werewolves."])}
    },
    "schwarmerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enamored"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The enamored falls in love with the first night-active role that targets them before the third night. If this creates a mixed couple, they only win individually. Unattached, the enamored wins with the village."])}
    },
    "seherLehrlingI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seer Apprentice"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The seer apprentice takes over after the seer's death. They are a wise person who can look into the hearts of the residents and see their allegiance."])}
    },
    "trunkenboldI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drunken Fool"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The drunken fool learns their true role only on the second or third night. While they play as the drunken fool for the village, their true role may belong to another faction."])}
    },
    "diebI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thief"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the thief is in the game, two additional cards must be added to the deck, leaving two cards at the end. In the first night, the thief chooses one of the two cards and assumes that role. If one of these cards is a werewolf card, they must take it."])}
    },
    "uberlauferI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defector"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The defector wins with the village until bitten. If they would die from a werewolf attack, they instead become a werewolf and win with them. The defector is not called at night. They must independently check if they have turned into a werewolf or not (in offline mode, the game master must signal them with a sign if they've become a werewolf)."])}
    },
    "werwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werewolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A wild beast. Goes hunting every night with the pack and kills a villager. Wins when no more villagers are alive."])}
    },
    "superBoserWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Evil Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You play alongside the werewolves and wake up with them. In addition to your role as a werewolf, every night you can kill another person. Once the first werewolf dies, you lose this extra ability."])}
    },
    "mogliI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mowgli"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently, you are a simple villager. Once your role model (whom you choose on the first night) dies, you become a werewolf."])}
    },
    "sympatisantI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sympathizer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sympathizer is a helper of the wolves but does not know who they are. They win with the wolves but cannot win alone if all wolves are dead."])}
    },
    "urWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancient Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You play alongside the werewolves. In addition to your role as a werewolf, once you can transform the selected victim into a werewolf instead of killing them."])}
    },
    "albtraumwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nightmare Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The nightmare wolf initially does not know the wolves but joins the pack once the first werewolf dies. However, they cannot be eaten before that."])}
    },
    "auserwahlterI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen One"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chosen one is a helper of the wolves who knows them and wins with them. If the last wolf dies before them, they themselves become a wolf."])}
    },
    "nachtschattenWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nightshade Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The nightshade wolf plays alongside the werewolves and wakes up with them. Additionally, every night, they can check the faction of a person."])}
    },
    "polarWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polar Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The polar wolf plays alongside the werewolves and wakes up with them. Additionally, every night, they can manipulate a player so that they do not wake up for the night (but not the same player twice in a row). If that player has a night action, it gets blocked."])}
    },
    "traumwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dream Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The dream wolf initially does not know the wolves but joins the pack once the first werewolf dies. Before joining the pack, they can be eaten by the wolves."])}
    },
    "werwolfSchamaneI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werewolf Shaman"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The werewolf shaman can choose any player every night, and if the seer chooses that player, they will be shown as a villager. The WWS cannot choose the same player twice in a row."])}
    },
    "zaubermeisterinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorceress"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sorceress is a wolf helper, but she doesn't know the wolves, nor do they know her. She also does not wake up with the werewolves at night. Every night, she can learn the faction of a player."])}
    },
    "weiserWerwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wise Werewolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cold-blooded murderer. Wants to kill all villagers AND all werewolves. Wakes up with the werewolves and additionally every second night to kill a werewolf. Wins alone."])}
    },
    "jsonI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Json"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If Jason is hanged, he automatically wins the game, and it ends immediately."])}
    },
    "grafDraculaI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count Dracula"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You play alone against everyone. Every night, you bite a player to infect them. If all living players are infected, you win. An infected player doesn't know they are infected and behaves normally."])}
    },
    "wolfshundI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolfhound"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The wolfhound decides at the beginning of the game whether it wants to be a werewolf or a villager by either opening or keeping its eyes closed during the first werewolf phase."])}
    },
    "engelI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angel"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you manage to be hanged on the first day, the game ends, and you win alone. If you don't achieve this, you become a regular villager."])}
    },
    "grabpluendererI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grave Digger"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The grave digger can dig up a dead player every night and learn their role."])}
    },
    "intruderI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intruder"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Intruder is perceived by everyone as a regular villager. However, they win alone if only they and exactly one villager (a player belonging to the Villager faction) are alive. \n\n The Werewolves cannot devour the Intruder. \n\n Each night, the Intruder can protect up to two players of their choice (excluding themselves) from death (including being lynched during the day). \n\n Every other night, they can additionally choose a player to eliminate."])}
    },
    "schattenwirkerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadowworker"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Shadowworker is an ally of the Werewolves, aiming to win with them, but they do not know their companions nor wake up with them. \n They cannot be devoured. \n\n As a master of dark magic, they can empower a chosen player (excluding themselves) each night, ensuring that this player survives a lynching during the day."])}
    },
    "werwolfAltesteI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werewolf Elder"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Werewolf Elder is a werewolf who cannot be devoured. \n\n Additionally, they awaken separately after the werewolves and have the authority to change the initially chosen werewolf target on their own."])}
    },
    "reuevollerSoldatI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repentant Soldier"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Repentant Soldier is a villager who cannot be devoured due to their resilience.\n\nHowever, having witnessed immense death and suffering, they vowed to never again be involved in the death of an innocent villager.\n\nThey die if they voted for a villager during the day who gets lynched at the end of the day."])}
    }
  },
  "ghostCardsI18n": {
    "echoGeistI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echo Ghost"])},
      "kurzInfo": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May communicate limitedly"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May open eyes at night"])}
      ],
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Echo Ghost still wins and loses with their original team. \nThey have the ability to communicate with living players and may open their eyes at night at any time. \n\nHowever, they are not allowed to speak, gesture, or point at others at any time. \n\nThey communicate exclusively through words suggested during the night."])}
    },
    "wahlGeistI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Ghost"])},
      "kurzInfo": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot communicate"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep eyes closed at night"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May vote anonymously daily"])}
      ],
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Vote Ghost still wins and loses with their original team. They may vote during the daily voting, but their vote has less weight than that of a living player. The percentage of their vote depends on the number of Vote Ghosts in the game. A Vote Ghost is not allowed to communicate with others at any time and must keep their eyes closed at night. \n\n 1-2 Vote Ghosts: 1 \n 3-4 Vote Ghosts: 0.5 \n 5-6 Vote Ghosts: 0.35 \n 7+ Vote Ghosts: 0.25"])}
    }
  },
  "ghostWords": {
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "gross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tall"])},
    "klein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short"])},
    "dick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thick"])},
    "duenn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thin"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long"])},
    "kurz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short"])},
    "blond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blond"])},
    "bruennett": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunette"])},
    "schwarzhaarig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black-haired"])},
    "lockig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curly"])},
    "glatt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straight"])},
    "kraus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinky"])},
    "blauaeugig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue-eyed"])},
    "gruenaeugig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green-eyed"])},
    "braunaeugig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brown-eyed"])},
    "sommersprossig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freckled"])},
    "bartlos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beardless"])},
    "baertig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearded"])},
    "schnurrbaertig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moustached"])},
    "brillentragend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wearing glasses"])},
    "brillenlos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glasses-free"])},
    "schmal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slim"])},
    "breit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broad"])},
    "jugendlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youthful"])},
    "reif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mature"])},
    "elegant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegant"])},
    "sportlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporty"])},
    "gemuetlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosy"])},
    "modern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern"])},
    "traditionell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional"])},
    "charismatisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charismatic"])},
    "sympathisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sympathetic"])},
    "ernst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serious"])},
    "froehlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheerful"])},
    "verschmitzt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mischievous"])},
    "schuechtern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shy"])},
    "selbstbewusst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-confident"])},
    "natuerlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural"])},
    "geschminkt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make-up"])},
    "ungekaemmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untamed"])},
    "ordentlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neat"])},
    "laessig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casual"])},
    "schick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chic"])},
    "leger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaxed"])},
    "extravagant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extravagant"])},
    "naturschoen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naturally beautiful"])},
    "schlank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slim"])},
    "muskuloes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muscular"])},
    "zierlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petite"])},
    "rot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "blau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue"])},
    "gruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
    "gelb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow"])},
    "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange"])},
    "violett": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purple"])},
    "rosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pink"])},
    "braun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brown"])},
    "schwarz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
    "weiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
    "grau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gray"])},
    "gold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
    "silber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
    "tuerkis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquoise"])},
    "magenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magenta"])},
    "kastanienbraun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chestnut brown"])},
    "olivgruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olive green"])},
    "himmelblau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sky blue"])},
    "koralle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coral"])},
    "limettengruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lime green"])},
    "beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
    "hellblau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light blue"])},
    "dunkelblau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark blue"])},
    "hellgruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light green"])},
    "dunkelgruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark green"])},
    "hellbraun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light brown"])},
    "dunkelbraun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark brown"])},
    "hellgrau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light grey"])},
    "dunkelgrau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark grey"])},
    "zartrosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pale pink"])},
    "halskette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necklace"])},
    "armband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bracelet"])},
    "ohrringe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earrings"])},
    "ring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ring"])},
    "anhaenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendant"])},
    "uhr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
    "brosche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brooch"])},
    "haarspange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hairpin"])},
    "manschettenknoepfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cufflinks"])},
    "fusskette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anklet"])},
    "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero"])},
    "eins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One"])},
    "zwei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two"])},
    "drei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three"])},
    "vier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Four"])},
    "fuenf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Five"])},
    "sechs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Six"])},
    "sieben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seven"])},
    "acht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eight"])},
    "neun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nine"])},
    "zehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten"])},
    "elf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleven"])},
    "zwoelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twelve"])},
    "dreizehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirteen"])},
    "vierzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourteen"])},
    "fuenfzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fifteen"])},
    "sechzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sixteen"])},
    "siebzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seventeen"])},
    "achtzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eighteen"])},
    "neunzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nineteen"])},
    "zwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty"])},
    "einundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-one"])},
    "zweiundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-two"])},
    "dreiundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-three"])},
    "vierundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-four"])},
    "fuenfundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-five"])},
    "sechsundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-six"])},
    "siebenundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-seven"])},
    "achtundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-eight"])},
    "neunundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twenty-nine"])},
    "dreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty"])},
    "einunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-one"])},
    "zweiunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-two"])},
    "dreiunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-three"])},
    "vierunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-four"])},
    "fuenfunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-five"])},
    "sechsunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-six"])},
    "siebenunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-seven"])},
    "achtunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-eight"])},
    "neununddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirty-nine"])},
    "vierzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forty"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
    "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
    "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
    "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
    "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H"])},
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I"])},
    "j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])},
    "k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
    "n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
    "o": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T"])},
    "u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U"])},
    "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V"])},
    "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
    "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
    "z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
    "nebelverhangen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foggy"])},
    "duester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gloomy"])},
    "schaurig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghoulish"])},
    "geisterhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghostly"])},
    "verlassen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned"])},
    "gespenstisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerie"])},
    "unheimlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncanny"])},
    "verwittert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weathered"])},
    "stille": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silent"])},
    "dunkel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
    "schattenhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadowy"])},
    "kalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold"])},
    "mystisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mystical"])},
    "gruselig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creepy"])},
    "abgelegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
    "gespensterhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spectral"])},
    "schleierhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enigmatic"])},
    "raetselhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puzzling"])},
    "spukend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haunting"])},
    "einsam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lonely"])},
    "verfallen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decayed"])},
    "geheimnisvoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mysterious"])},
    "nachtumhuellt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night-shrouded"])},
    "verwunschen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enchanted"])},
    "schrecklich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrible"])},
    "atmosphaerisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmospheric"])},
    "gaensehaut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goosebumps"])},
    "trueb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dull"])},
    "geistervoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spirited"])},
    "erschreckend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scary"])},
    "pizza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pizza"])},
    "hamburger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburger"])},
    "sushi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sushi"])},
    "spaghetti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaghetti"])},
    "currywurst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currywurst"])},
    "croissant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissant"])},
    "sauerbraten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauerbraten"])},
    "paella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paella"])},
    "tacos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tacos"])},
    "curry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curry"])},
    "kimchi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimchi"])}
  },
  "reduceVote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce Voting Power (1)"])},
  "reduceVote05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce Voting Power (0.5)"])},
  "powerVote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase Voting Power (1)"])},
  "powerVote05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase Voting Power (0.5)"])},
  "reduceVote1-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-1"])},
  "powerVote1-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1"])},
  "powerVote05-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+0.5"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
  "eat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eat"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
  "shoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoot"])},
  "becomeWolfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become werewolf"])},
  "showCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show card"])},
  "showFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show fraction"])},
  "todesTrank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Death potion"])},
  "heilTrank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healing potion"])},
  "todesTrank-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kill"])},
  "heilTrank-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heal"])},
  "heal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heal"])},
  "uebernachten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spend the night"])},
  "transformToWolf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transform to werewolf"])},
  "twoVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 votes"])},
  "eatExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eat extra"])},
  "bless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bless"])},
  "protect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect"])},
  "saveFromHang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't hang"])},
  "showAsVillager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show as villager"])},
  "selectCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select card"])},
  "love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Love"])},
  "blockVote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block vote"])},
  "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze"])},
  "killWolf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kill werewolf"])},
  "restrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrict"])},
  "voteTwice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote twice"])},
  "vampire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vampire"])},
  "kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kill"])},
  "getCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get card"])},
  "vorbild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role model"])},
  "sacrifice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacrifice"])},
  "errorInvalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
  "errorRequiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "cardSavedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card saved successfully"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "registerWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to login"])},
  "registerViewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "loginViewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "loginWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "loginWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Google"])},
  "continueWithoutLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue without login"])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to register"])},
  "passwordOrEmailIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password or email is incorrect"])},
  "userCreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User created successfully"])},
  "filedRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
  "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
  "sendResetEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reset email"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "resetEmailSentSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reset email has been sent to your email address. It might be under spam folder."])},
  "restEmailFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send reset email"])},
  "anonymousLoginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Check your internet connection and try again."])},
  "continueOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue offline"])},
  "offlineDisplayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who am I"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "inGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected for game"])},
  "notInGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not selected"])},
  "createCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
  "cardDeck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deck"])},
  "cardStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the App developer"])},
  "communityCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
  "createdByOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by others"])},
  "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorials"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "appSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App settings"])},
  "defaultGameSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default game settings"])},
  "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf App"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update profile"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "profileUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated successfully"])},
  "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
  "passwordsDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 6 characters long"])},
  "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is already in use"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Check your internet connection and try again."])},
  "userDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is disabled"])},
  "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
  "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
  "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
  "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "howStrongIsThatCardForTheVillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How strong is that card for the Village?"])},
  "aboutAsStrongAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approximately as strong as:"])},
  "DORFBEWOHNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villager"])},
  "WERWOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werewolf"])},
  "ALLEINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plays Alone"])},
  "SWITCHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villager-Werewolf-Mix"])},
  "VOR_AMOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before the Amor"])},
  "NACH_AMOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the Amor"])},
  "VOR_WERWOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before the Werewolves"])},
  "NACH_WERWOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the Werewolves"])},
  "VOR_HEXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before the Witch"])},
  "NACH_HEXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the Witch"])},
  "fraktion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faction"])},
  "exemplare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copies"])},
  "cardNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language you write in"])},
  "playWithDayRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day roles"])},
  "dayRoleSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day role selection"])},
  "playWithDayRolesHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to the secret night roles, each player is assigned a Day Role. These Day Roles are openly visible to everyone. \nDay Roles, much like the already familiar night roles, have special abilities that can be used during the day.\n\nFor example: Maria could be a werewolf and receive the \"Priest\" day role. Every player knows that Maria is a priest, but not that she is also a werewolf. As a priest, Maria has the one-time ability to look at the night role card of any player during the day."])},
  "playWithGhostCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost cards"])},
  "ghostCardSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost card selection"])},
  "playWithGhostCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Ghost Cards are special cards that are only used when a player dies. When a player dies, he transforms into a Ghost. As a ghost the player continues to play the Game and dose not have to leave the round. Ghosts are not allowed to communicat with the living, but they may communicat with each other. But only during the day and only along as no living Player hears them.  \n\nFor example: If Maria dies -somehow- she could transform into a \"Echo Ghost\". As an echo ghost, Maria has the ability to communicate with the living players. But the communication is strongly limited."])},
  "variableNrExemplare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable number of copies"])},
  "variableNrExemplareHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can the Game Master control how may copies of this card are in the game? Like the Werewolves or Villagers Cards"])},
  "exemplareHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many copies of this card are in the game? For example, the Sisters always have 2 copies and the Brothers always have 3 copies in game."])},
  "callAtNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wake up at night"])},
  "callAtNightHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does this card wake up at night? For example, the Werewolves wake up at night, but the Villagers don't."])},
  "callOnlyInTheFirstNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only in the first night"])},
  "callOnlyInTheFirstNightHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does this card wake up only in the first night? For example, the Amor wakes up only in the first night, but the Werewolves wake up every night."])},
  "addActiveEffect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add active effect"])},
  "addPassiveEffect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add passive effect"])},
  "NORMAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "KANN_NICHT_GEFRESSEN_WERDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't be eaten"])},
  "UNSTERBLICH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immortal"])},
  "sterblichkeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortality"])},
  "sterblichkeitHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this character impossible to be eaten by the Werewolves or even immortal?"])},
  "sterblichkeitsdauer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortality duration"])},
  "sterblichkeitsdauerHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long dose the effect of immortality last?"])},
  "EINMALIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once"])},
  "DAUERHAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent"])},
  "ZWEIMAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twice"])},
  "DREIMAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thrice"])},
  "WAEHREND_KARTE_IM_SPIEL_IST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While card is in game"])},
  "WAEHREND_KARTE_AM_LEBEN_IST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While card is alive"])},
  "WAEHREND_KARTE_TOT_IST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While card is dead"])},
  "BIS_KARTE_STIRBT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until card dies"])},
  "BIS_ERSTE_KARTE_EINER_FRAKTION_STIRBT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until first card of a faction dies"])},
  "manipulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manipulation"])},
  "manipulationFalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If"])},
  "NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "ALS_ANDERE_ROLLE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show as different role"])},
  "ALS_ANDERE_FRAKTION_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show as different faction"])},
  "ALS_ANDERE_KARTE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show a different card"])},
  "zuWelcherFraktion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which faction?"])},
  "reallyWantToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this card?"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "CreateGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create game"])},
  "aktivierFrequenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation frequency"])},
  "aktivierFrequenzHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How often can this effect be used?"])},
  "dauer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "dauerHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long does this effect last?"])},
  "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
  "possibleTargets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible targets"])},
  "possibleTargetsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can be targeted by this effect?"])},
  "numberOfTargets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of targets"])},
  "numberOfTargetsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many Players must be targeted by this effect? For example, the Amor needs to target 2 Players."])},
  "zweiMalDenGleichen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same Player twice in a row"])},
  "zweiMalDenGleichenHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it possible to target the same player in two consecutive nights, or is there a requirement to have at least one night in between where someone else is chosen?"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "aktion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "aktionHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should an action be performed by the effect, or is it sufficient for the game master to see that the selected player has been affected by this effect?"])},
  "stirbtBeiAnwendung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies when used"])},
  "stirbtBeiAnwendungHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does this card die when it uses this effect?"])},
  "JEDE_NACHT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Every ", _interpolate(_named("state"))])},
  "JEDE_ZWEITE_NACHT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Every second ", _interpolate(_named("state"))])},
  "JEDE_DRITTE_NACHT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Every third ", _interpolate(_named("state"))])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["night"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nights"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "ZWEIMALIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twice"])},
  "DREIMALIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thrice"])},
  "BIS_NAECHSTE_NACHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until the next night"])},
  "BIS_NAECHSTEN_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until the next day"])},
  "ZWEI_TAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two days"])},
  "DREI_TAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three days"])},
  "GANZE_SPIEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole game"])},
  "ZWEI_NAECHTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two nights"])},
  "DREI_NAECHTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three nights"])},
  "ALLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Players"])},
  "LEBENDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alive Players"])},
  "assignCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign card"])},
  "TOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dead Players"])},
  "WERWOLF_OPFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werewolf victim"])},
  "ALLE_STERBENDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Players who would die this night"])},
  "NUR_SICH_SELBST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only itself"])},
  "ALLE_AUSSER_SICH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Players except itself"])},
  "LEBENDE_AUSSER_SICH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alive Players except itself"])},
  "KEINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "KARTE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show card of target"])},
  "KARTE_ZUWEISEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign a specific card to the target"])},
  "NEUE_KARTE_AUS_ALLEN_GEBEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign a card from all cards in your deck to the target"])},
  "NEUE_KARTE_AUS_UEBRIGEN_GEBEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign a card from unassigned cards in play"])},
  "FRAKTION_WECHSELN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change faction of target"])},
  "FRAKTION_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show faction of target"])},
  "ROLLE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show character of target (fraction is not shown)"])},
  "STIRBT_NICHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "STIRBT_FALLS_FRAKTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, if target has faction"])},
  "STIRBT_FALLS_KARTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, if target has character"])},
  "STIRBT_IMMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, always"])},
  "killOrSaveTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kills/save"])},
  "killOrSaveTargetHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does this effect kill or save the target?"])},
  "NICHTS_TUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No changes"])},
  "TOETEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kills"])},
  "VOR_WERWOLF_RETTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saves from Werewolves"])},
  "VOR_TOT_BEWAHREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saves from death at night"])},
  "VOR_TOT_BEWAHREN_INKLUSIVE_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saves from death (including day)"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "nightActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night activity"])},
  "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
  "EAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as eaten"])},
  "FALL_IN_LOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In love"])},
  "effects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effects"])},
  "nameSuccessfullyChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name successfully changed"])},
  "nameChangedFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to change name"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "activeEffects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active effects"])},
  "passiveEffects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive effects"])},
  "saveCardLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save card"])},
  "publishCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish card to the community"])},
  "clearCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear card"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "deathBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Death by"])},
  "deathByHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does this card die if the effect is used?"])},
  "KILLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Killed during night"])},
  "EATEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as Werewolf target"])},
  "DAILY_VOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Killed during daily vote"])},
  "clearCardConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to clear this card?"])},
  "reallyWantToDeleteConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this effect?"])},
  "removeCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove card"])},
  "removeCardConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to remove this card?"])},
  "removeCardOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Card"])},
  "saveEditCardLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "cardEditSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card changes saved"])},
  "editCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit card"])},
  "whenShouldThisCardBeCalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When should this card be called?"])},
  "cardPublishedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card published successfully"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "basicKarte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic card"])},
  "basicKarteHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this a basic card?"])},
  "errorLoadingBasicCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading basic cards"])},
  "deleteCardFromCloudConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this card from the cloud?"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete card"])},
  "cardDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card deleted"])},
  "yourMitgliedschaftWasUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your membership was updated"])},
  "errorUpdatingYourMitgliedschaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error updating your membership"])},
  "paymentWasCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment was canceled"])},
  "errorDuringPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during payment"])},
  "MARK_AS_WEREWOLF_TARGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as Werewolf target"])},
  "KANN_NICHT_GELYNCHT_WERDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't be hanged during the day"])},
  "createCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create copy"])},
  "showDeadPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show dead players"])},
  "showDeadPlayersHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should dead players be shown in the player list?"])},
  "showFirstNightAlways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show first night always"])},
  "showFirstNightAlwaysHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should the Cards, that you call up only during the first night, always be shown?"])},
  "nightPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night phase"])},
  "nightPhaseHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What cards do you want to call up at night? \n\n All: All cards in your Deck \n Selected cards: Cards you selected for the Game \n In play: Only cards that are currently in play (Also shows cards of dead players) \n Only necessary: Only cards that are necessary right now"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "SELECTED_CARDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected cards"])},
  "ONLY_NECESSARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only necessary"])},
  "IN_PLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In play"])},
  "IN_PLAY_AND_EXTRA_CARDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In play and extra cards"])},
  "godMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["God mode"])},
  "godModeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In god mode, you can change cards of players, reorder the Players and much more."])},
  "joinGameOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game of"])},
  "numberOfPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Players"])},
  "numberOfCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Cards"])},
  "balancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balancing"])},
  "PlaygroundOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playground (online)"])},
  "PlaygroundOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playground (offline)"])},
  "selectRandomCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select random cards"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
  "ScanToJoinGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan to join"])},
  "closeGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close game"])},
  "closeGameConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to close this game?"])},
  "gameIsRunning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is running"])},
  "gameStopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game stopped"])},
  "JoinGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join game"])},
  "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf App"])},
  "noCardYetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No card yet"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "GameSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game settings"])},
  "numberOfExtraCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number extra cards"])},
  "numberOfExtraCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can specify how many cards extending the number of Players should be added to the game. \n\n For example, if you have 5 players and 2 extra cards, 7 cards will be selected randomly from the deck."])},
  "CreateCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create card"])},
  "CardDeck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
  "CommunityCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community cards"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "Tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorials"])},
  "AppSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App settings"])},
  "joinAGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join game"])},
  "createAGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create game"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "onOneDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On one device"])},
  "onMultipleDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On multiple devices"])},
  "joiningGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joining game..."])},
  "showPlayersSelectedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show players selected cards"])},
  "showPlayersSelectedCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should players see the cards you selected for this game?"])},
  "showExemplare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show exemplare"])},
  "showExemplareHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should players see the how many cards of a kind are in the game?"])},
  "addPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add player"])},
  "showAllCardsToPlayersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show cards to players"])},
  "showAllCardsToPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all cards to players"])},
  "showAllCardsToPlayersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass this phone to the players who play without there phones, so they can see their cards."])},
  "passPhoneTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pass the phone to ", _interpolate(_named("name"))])},
  "holdToSeeCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold to see your card"])},
  "nextPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next player"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "allPlayerSawThereCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All players saw their cards. You can close this dialog and continue the Game."])},
  "passToGameMaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass to game master"])},
  "nextNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next night"])},
  "hasI18nKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has i18n key"])},
  "hasI18nKeyHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should this card have an i18n key?"])},
  "i18nKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i18n key"])},
  "viewSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your view"])},
  "nightSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nights"])},
  "addons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
  "deckSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
  "playerViewSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player view"])},
  "minOneSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. 1 selection"])},
  "minOneSelectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least 1"])},
  "GOT_EATEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got eaten"])},
  "become": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become"])},
  "youAreDead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are dead"])},
  "ALWAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always"])},
  "GOT_KILLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got killed"])},
  "HANG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanged"])},
  "ASSIGN_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign card"])},
  "dorfWon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village won"])},
  "werewolvesWon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werewolves won"])},
  "playerWon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("playerName")), " won"])},
  "selfTargetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can target itself"])},
  "clickToShowCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
  "clickToShowGhostCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost card "])},
  "clickToShowDayCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day role"])},
  "clickToShowGhostWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost words"])},
  "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide"])},
  "selectCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select cards"])},
  "selfTargetableHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can this card effect be used on itself?"])},
  "successfullySendToGm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
  "failedToSendToGm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save"])},
  "loadingDataFromServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading data from server..."])},
  "selectAWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a word"])},
  "startVote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start vote"])},
  "voteForPlayerToDie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote for a player to hang"])},
  "votingResultOfDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voting result of day ", _interpolate(_named("day"))])},
  "calculating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculating..."])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "votingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting results"])},
  "showCardsOfDeadPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show cards of dead players"])},
  "showCardsOfDeadPlayersHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should the cards of dead players be shown to the players? They will see the cards of the dead players next to their name in the player list. \\n (Just like you see the player list)"])},
  "youWereKicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were kicked from the game"])},
  "youLeftTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left the game"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
  "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max. length is ", _interpolate(_named("maxLength")), "!"])},
  "churchWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Church Wall"])},
  "showNrOfWerewolves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show number of werewolves"])},
  "showNrOfWerewolvesHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should the approximated number of werewolves (+-1) be shown to the players? \n\n Useful when you dont show the cards of dead players."])},
  "approximatedNrOfWolfes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr. of alive werewolves"])},
  "approximated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(approximated)"])},
  "wirteSomething": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write something"])},
  "churchWallDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Day ", _interpolate(_named("day"))])},
  "village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You"])},
  "openMainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home menu"])},
  "church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Church"])},
  "townHall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rathaus"])},
  "churchWallHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Church Wall is an extra Tab for the Players. There they have a Wall were every alive Player can write something each day anonymously"])},
  "churchWallPlayerViewHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the Church Wall you can write something each day anonymously and you see what other Players wrote."])},
  "possibleCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible cards"])},
  "votingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting time"])},
  "votingTimeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long should the players have time to vote?"])},
  "churchWallMaxCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Church wall max. chars."])},
  "churchWallMaxCharactersHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many characters can a player write on the church wall each day?"])},
  "kickPlayerFromGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick player"])},
  "manipulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manipulate"])},
  "showOriginalFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show fraction (ignore effects)"])},
  "showOriginalCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show card (ignore effects)"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "voteValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote value"])},
  "noVotingResultsYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No voting results yet"])},
  "youCantVote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't vote"])},
  "enthaltungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstentions"])},
  "BASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
  "ADVANCED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
  "FROM_OTHERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From others"])},
  "OWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own"])},
  "gameWasClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game was closed"])},
  "ghostChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install"])},
  "install_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Werwolf as an app"])},
  "defaultGameSettingsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This settings will be used as a template for every game you create."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "readPrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the privacy policy."])},
  "STIMMRECHT_ANPASSEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manipulate voting value"])},
  "votingValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote value"])},
  "votingValueHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much should the value of the vote be changed? For example, a value of -0.25 means that this player can only vote with 0.75 instead of the default value of 1 during the daily voting."])},
  "minutesAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "secondsAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
  "hoursAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
  "swipteThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swipe sensitivity"])},
  "waitingForPlayersToJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for players"])},
  "waitingForPlayersToJoinInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for players to join the game. In the meantime, you can select the cards and make settings."])},
  "manuelAddPlayerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a player doesn't have a smartphone or doesn't want to play on their smartphone, you can add them manually."])},
  "filterByNameOrId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Name or Game ID"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "youWereDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were declined"])},
  "waitingForAcceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for acceptance"])},
  "playersWantToJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player requests"])},
  "becauseAnonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account to use this feature."])},
  "anonymousUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous account"])},
  "anonymousUserDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently logged in anonymously. This account will automatically get deleted after 30 days. Furthermore, you can't use all features of the app. If you like the app, consider creating an account or login with Google."])},
  "startDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start day"])},
  "startNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start night"])},
  "stopGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop game"])},
  "playerWithMostVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player with most votes"])},
  "noOneGetsHanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No one gets hanged"])},
  "addPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add players"])},
  "addPlayersInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 5 players to the game. Those Players don't need to have a smartphone."])},
  "addPlayersInfoPassAround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On game start the App will tell you to pass this Phone around, so every player can see their card."])},
  "nrAliveWerwolfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr. of alive werewolves (incl. helpers)"])},
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
  "install_description_ios_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Werwolf as an app on your iPhone: tab"])},
  "install_description_ios_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and then"])},
  "install_description_ios_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Home Screen."])},
  "youAreOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are offline"])},
  "youAreOfflineDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can continue using the app, but you can only create offline games."])},
  "offlineGameDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In an offline game, you'll need to pass the phone around so each player can see their card. The app will prompt you to pass the phone when the game starts."])},
  "createOfflineGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Offline Game"])},
  "possibleUnassignedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible unassigned cards"])},
  "showPlayersExtraVillagerCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra villager cards"])},
  "showPlayersExtraVillagerCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should the players see the extra villager cards? Those cards are not in the game, but they are shown to the players."])},
  "showWerwolfsOpenRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Werewolves open roles"])},
  "showWerwolfsOpenRolesHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you activate this option, the Werewolves will see which additional Villager cards are present. This makes it easier for them to lie about their role. \n\n They will be shown all additional cards as well as X occupied cards. This means they cannot know for certain which cards are free or occupied. They receive only one clue. \n\n X is the rounded half of the additional cards."])},
  "playerCircleView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circle View"])},
  "dragPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort players"])},
  "hideFabCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Temporarily"])},
  "playerCircleSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Circle Size"])},
  "playerCircleRound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rounding"])},
  "cardsPlayerView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards view (Players)"])},
  "dead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (DEAD)"])},
  "placeHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placeholder"])},
  "votingSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting"])},
  "voteDuringDayTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuous Voting"])},
  "voteDuringDayTimeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When this option is enabled, players can vote throughout the day while the game is ongoing, without needing a specific prompt from you. \n\n Players have insight into the current votes, allowing them to see who voted for whom. The votes of the spirits (if present) are kept hidden and only revealed when you end the day's voting. \n\n If this option is disabled, players can only vote when you explicitly start the voting. They have no way to vote outside of this specific voting time."])},
  "reopenVoteForToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen Voting Time"])},
  "stopVoteForToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Voting Time"])},
  "replyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
  "noMessagesYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No messages yet"])},
  "newMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New messages"])},
  "toggleToShowCardHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show card"])},
  "toggleToShowCardDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tap the toggle ("])},
  "toggleToShowCardDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([") to display your card."])},
  "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])},
  "btnSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button size"])},
  "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small"])},
  "large": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large"])},
  "cantChooseThisPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't choose this player"])}
}