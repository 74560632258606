export default {
  "cardsI18n": {
    "reduceVote1-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verringern"])},
    "reduceVote05-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verringern"])},
    "powerVote1-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhen"])},
    "powerVote05-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhen"])},
    "showCard-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen"])},
    "dayPfaffer": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pfarrer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Pfarrer darf einmal am Tag die Karte eines lebenden Spielers ansehen."])}
    },
    "dayFridhofpfleger": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friedhof-Pfleger"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Friedhof-Pfleger darf einmal am Tag die Karte eines toten Spielers ansehen."])}
    },
    "dayLehrerin": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrerin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lehrerin darf jeden Tag einem lebenden Spieler das Stimmrecht um 1 reduzieren. Sie kann diesen Effekt nicht an zwei aufeinanderfolgenden Tagen auf den selben Spieler anwenden."])}
    },
    "dayGhostWhisperer": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost Whisperer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ghost Whisperer jeden Tag einem Toten das Stimmrecht um 0.5 erhöhen. Er kann diesen Effekt nicht an zwei aufeinanderfolgenden Tagen auf den selben Spieler anwenden."])}
    },
    "daySmellyGuy": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stinker"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Stinker hat schon seid einigen Monaten nicht mehr geduscht... er stinkt so sehr, dass ihn die Werwölfe nicht fressen (er kann nicht von den Werwölfen gefressen werden)."])}
    },
    "dayFakelMeister": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakel Meister"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fakel Meister kann vom Dorf während der Abstimmung nicht getötet werden, solange alle Werwölfe noch am leben sind. \n\n Hat er am Ende er Abstimmung die meisten Stimmen, stirbt er nur wenn mindesten ein Werwolf bereits gestorben ist. Andern falls stirbt niemand und es wird Nacht."])}
    },
    "dayBuerger": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bürger"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bürger können jeden Tag einen Bürgervertreter wählen. Dieser hat für die Abstimmung am ende des Tages ein doppeltes Stimmrecht (sein Stimmrecht erhöht sich um 1). Es kann nur einen Bürgervertreter geben und dieser muss jeden Tag neu bestimmt werden"])}
    },
    "daySuendenbock": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündenbock"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine einzige Fähigkeit besteht darin, dass falls es bei der Abstimmung am Tag zu einem Unentschieden kommt, musst du anstelle des Angeklagten sterben. Wenigstens kannst du, wenn du auf diese Weiße stirbst, bestimmen wer am nächsten Tag wählen darf und wer nicht. Bemerke: Wenn alle Spieler die wählen dürfen in der Nacht sterben, dann gibt es keine Abstimmung am folgenden Tag. Danach kehrt alles wieder zum Normalen zurück. Aber du bist immer noch tot."])}
    },
    "daySchuetze": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schütze"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schütze darf jeden Tag auf einen Spieler schießen. Gehört dieser den Werwölfen an, so stirbt dieser. Gehört er zum Dorf, so stirbt der Schütze."])}
    },
    "assigeFromUnssed": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unused"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This card is unused and only a placeholder."])}
    },
    "assigeFromALl": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unused"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This card is unused and only a placeholder."])}
    },
    "emptyCardI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Karte ist leer und nur als Platzhalter gedacht."])}
    },
    "dorfbewohnerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorfbewohner"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist ein normaler Dorfbewohner, ohne besondere Fähigkeiten. Du musst versuchen, die Werwölfe zu entlarven und zu töten. Gewinnt zusammen mit dem Dorf wenn alle Werwölfe tot sind."])}
    },
    "heilerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heiler"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein wandernder Heiler, der jede Nacht einen Bewohner des Dorfes besucht und beschützt, auch sich selbst. Er darf die selbe Person nicht zweimal hintereinander schützen. Gewinnt zusammen mit dem Dorf wenn alle Werwölfe tot sind."])}
    },
    "seherI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seher"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du darfst dir jede nacht die Karte von einem lebenden Spieler ansehen."])}
    },
    "hexeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hexe"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine alte Hexe, die einen Heiltrank und einen Gifttrank hat, mit denen sie das Opfer der Werwölfe retten oder einen Dorfbewohner ihrer Wahl töten kann. Gewinnt zusammen mit dem Dorf wenn alle Werwölfe tot sind."])}
    },
    "mrFreezI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr. Freeze"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Nacht musst du eine Person einfrieren (du kannst nicht die selbe Person zweimal hintereinander einfrieren). Eine gefrorene Person kann nicht sterben, wählen oder gar reden. Sie ist bis zum Anbruch der nächsten Nacht gefroren."])}
    },
    "amorI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amor"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der ersten Nacht wählst du zwei Spieler aus, die sich ineinander verlieben. Diese Spieler sind dann unsterblich ineinander verliebt und spielen, sofern sie Werwolf und Dorfbewohner sind, zusammen gegen alle. Stirbt einer von beiden, so stirbt auch der andere."])}
    },
    "jagerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäger"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du stirbst kannst du noch jemanden erschießen und mit dir in den Tod ziehen (egal wen)."])}
    },
    "trottelI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trottel"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedes Dorf hat einen Trottel. Wer hätte gedacht, dass du das bist? Wenn das Dorf dich am Tag durch die Abstimmung töten will erkennen sie im letzten Moment, dass du ja nur der Trottel bist und verschonen dich. Aber ein Stimmrecht wird um 0.5 reduziert."])}
    },
    "magdI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magd"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald jemand am Tag durch die Abstimmung getötet wird, kannst du, bevor seine Rolle enthüllt wird, diese einnehmen. Du bist dann nicht mehr die Magd, sondern diese Rolle mit all ihren Fähigkeiten. Einmalige Fähigkeiten dieser Rolle werden erneuert."])}
    },
    "sundenbockI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündenbock"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine einzige Fähigkeit besteht darin, dass falls es bei der Abstimmung am Tag zu einem Unentschieden kommt, musst du anstelle des Angeklagten sterben. Wenigstens kannst du, wenn du auf diese Weiße stirbst, bestimmen wer am nächsten Tag wählen darf und wer nicht. Bemerke: Wenn alle Spieler die wählen dürfen in der Nacht sterben, dann gibt es keine Abstimmung am folgenden Tag. Danach kehrt alles wieder zum Normalen zurück. Aber du bist immer noch tot."])}
    },
    "alteI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Alte überlebt den ersten Wolfsangriff und stirbt erst beim zweiten. Sollte er aber durch das Dorf getötet werden, verliert dieses alle seine Sonderrollen."])}
    },
    "augeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auge"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Auge erfährt in der ersten Nacht, wer der Seher und der Seher-Lehrling sind."])}
    },
    "beschworerinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschwörerin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Beschwörerin kann jede Nacht einem Spieler sein Stimmrecht entziehen (Stimmrecht -1)."])}
    },
    "chaosstiferinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaosstiferin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Chaos-Stifterin kann sich einmalig in der Nacht dazu entscheiden, Chaos zu stiften. Tut sie dies, so finden am Folgetag zwei getrennte Hängungen statt."])}
    },
    "drudeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druide"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann einmalig in der Nacht das Nachtopfer auf 3 Spieler einschränken."])}
    },
    "verlasenesMadchenI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlassenes Mädchen"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das verlassene Mädchen muss sich jede Nacht einen Spieler suchen, bei dem es übernachtet, sonst würde es erfrieren. Sollte dieser Spieler jedoch ein Wolf sein, so wird es anstelle des normalen Nachtopfers gefressen, der Wolf jedoch nicht entdeckt. Sie darf sich nicht zweimal hintereinander den selben Spieler aussuchen."])}
    },
    "schwesterI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwester"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die zwei Schwestern kennen sich. Sie wissen, dass sie Schwestern sind."])}
    },
    "bruderI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruder"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die drei Brüder kennen sich untereinander und wissen, dass keiner von ihnen ein Wolf ist."])}
    },
    "kreuterhaxeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kräuterhexe"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kräuterhexe erfährt jede Nacht das Werwolf-Opfer und kann dieses einmalig heilen, solange sie ihren Heiltrank noch nicht verbraucht hat."])}
    },
    "lykantropinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykanthropin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lykanthropin wird vom Seher und dessen Lehrling als Werwolf gesehen."])}
    },
    "martyrerinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Märtyrerin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Märtyrerin erfährt am Ende der Nacht die Namen aller sterbenden Spieler und kann sich für einen der Spieler opfern, welcher dafür überlebt."])}
    },
    "meuchelmorderI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meuchelmörder"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Meuchelmörder kann einmal Nachts im Spiel einen Spieler seiner Wahl töten."])}
    },
    "auraseherinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aura Seherin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Aura-Seherin kann einen Spieler auf seine Aura überprüfen. Sie erfährt, mit welcher Gruppe der Spieler gewinnt."])}
    },
    "neugierigerNachbarI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neugieriger Nachbar"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der neugierige Nachbar erfährt in der ersten Nacht die Rolle des Spielers links von ihm."])}
    },
    "phantomI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phantom"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Phantom kann sich jede Nacht einem beliebigen Spieler offenbaren (durch den Spielleiter)."])}
    },
    "priesterI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priester"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Priester kann einmalig in der Nacht einen anderen Spieler segnen. Ein gesegneter Spieler kann nie wieder in der Nacht sterben (ausgenommen Liebeskummer). Entscheide weise, wen du segnen möchtest."])}
    },
    "prinzI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prinz"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte der Prinz gehängt werden, so überlebt er den ersten Versuch und seine Rolle wird offenbart."])}
    },
    "rabeI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabe"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Rabe kann jede Nacht einem beliebigen Spieler einen Drohbrief schicken. Dieser Spieler startet dann bei der nächsten Abstimmung mit zwei Stimmen gegen sich."])}
    },
    "rotkappchenI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotkäppchen"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solang der Jäger lebt, kann das Rotkäppchen nicht von den Werwölfen gefressen werden."])}
    },
    "schwarmerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwärmerin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schwärmerin verliebt sich in die erste nachtaktive Rolle, die vor der dritten Nacht eine Aktion auf sie vornimmt. Entsteht dadurch ein gemischtes Liebespaar, gewinnt dieses nur alleine. Unverliebt gewinnt die Schwärmerin mit dem Dorf."])}
    },
    "seherLehrlingI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seher Lehrling"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Seher-Lehrling übernimmt nach dem Tod des Sehers. Dieser ist ein weiser Mann, der in die Herzen der Bewohner blicken kann und ihre Gesinnung sieht."])}
    },
    "trunkenboldI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trunkenbold"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Trunkenbold erfährt seine richtige Rolle erst in der zweiten bzw. dritten Nacht. Als Trunkenbold spiel er für das Dorf, seine wahre Rolle kann jedoch auch einer anderen Partei angehören."])}
    },
    "diebI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieb"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist der Dieb im Spiel, müssen zwei zusätzliche Karten ins Deck gelegt werden, so das am Ende zwei Karten übrig bleiben. Der Dieb wählt in der ersten Nacht eine der beiden Karten aus und übernimmt diese Rolle. Ist eine dieser Karten eine Werwolfkarte, so muss er diese nehmen."])}
    },
    "uberlauferI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überläufer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Überläufer gewinnt, bis er gebissen wird, mit dem Dorf. Würde er durch einen Werwolfangriff sterben, so wird er stattdessen selber zu einem Werwolf und gewinnt fortan mit diesen. Der Überläufer wird Nachts nicht aufgerufen. Er muss eigenständig überprüfen ob er zu einem Werwolf wurde oder nicht (im Offline Modus muss der Spielleiter ihm durch ein Zeichen signalisieren das er zu einem Werwolf wurde)."])}
    },
    "werwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein wildes Biest. Geht jede Nacht mit dem Rudel auf die Jagd und tötet einen Dorfbewohner. Gewinnt, wenn keine Dorfbewohner mehr am Leben sind."])}
    },
    "superBoserWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Böser Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du spielst zusammen mit den Werwölfen und wachst mit ihnen auf. Zusätzlich zu deiner Rolle als Werwolf kannst du jede Nacht eine weitere Person töten. Sobald der erste Werwolf stirbt, verlierst du diese Extrafähigkeit."])}
    },
    "mogliI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogli"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentan bist du ein einfacher Dorfbewohner. Sobald dein Vorbild (welches du in der ersten Nacht wählst) stirbt, wirst du zu einem Werwolf."])}
    },
    "sympatisantI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sympatisant"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Sympatisant ist ein Helfer der Wölfe, welcher diese jedoch nicht kennt. Er gewinnt mit den Wölfen, kann jedoch nicht alleine gewinnen, wenn alle Wölfe tot sind."])}
    },
    "urWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ur Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du spielst zusammen mit den Werwölfen. Zusätzlich zu deiner Rolle als Werwolf darfst du einmalig das ausgewählte Opfer statt es zu töten in einen Werwolf verwandeln."])}
    },
    "albtraumwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albtraumwolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Alptraumwolf kennt die Wölfe anfangs nicht, sondern tritt erst dem Rudel bei, wenn der erste Werwolf stirbt. Er kann jedoch auch davor nicht gefressen werden."])}
    },
    "auserwahlterI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auserwählter"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Auserwählte ist ein Helfer der Wölfe, welcher diese kennt und mit ihnen gewinnt. Stirbt der letzte Wolf vor ihm, so wird er selber zum Wolf."])}
    },
    "nachtschattenWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachtschatten Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nachtschattenwolf spielt zusammen mit den Werwölfen und wacht auch gemeinsam mit ihnen auf. Zusätzlich darf jede Nacht die Fraktion einer Person überprüfen."])}
    },
    "polarWolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polar Wolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Polarwolf spielt zusammen mit den Werwölfen und wacht auch mit diesen zusammen auf. Zusätzlich kann jede Nacht einen Spieler so manipulieren, dass er diese Nacht nicht aufwacht (jedoch nicht zweimal hintereinander den selben Spieler). Falls dieser eine Nachtaktion besitzt, wird sie blockiert."])}
    },
    "traumwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traumwolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Traumwolf kennt die Wölfe anfangs nicht, sondern tritt erst dem Rudel bei, wenn der erste Werwolf stirbt. Bevor er dem Rudel beitritt, kann er von den Wölfen gefressen werden."])}
    },
    "werwolfSchamaneI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolfschamane"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Werwolfschamane darf jede Nacht einen beliebigen Spieler wählen, der dem Seher in dieser Nacht (falls dieser ihn auswählt) als Dorfbewohner angezeigt wird. Der WWS darf hierbei nicht zweimal hintereinander den gleichen Spieler wählen."])}
    },
    "zaubermeisterinI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaubermeisterin"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zaubermeisterin ist ein Wolfshelfer, aber sie kennt weder die Wölfe noch kennen diese sie und sie wacht auch nicht mit den Werwölfen in der Nacht auf. Sie darf jede Nacht den die Fraktion eines Spielers erfahren."])}
    },
    "weiserWerwolfI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weißer Werwolf"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein kaltblütiger Mörder. Will alle Dorfbewohner UND alle Werwölfe töten. Er erwacht mit den Werwölfen und zusätzlich jede zweite Nacht und darf einen Werwolf töten. Gewinnt alleine."])}
    },
    "jsonI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Json"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird Jason gehängt, so gewinnt er automatisch das Spiel und dieses endet sofort."])}
    },
    "grafDraculaI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graf Dracula"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du spielst alleine gegen alle. Jede Nacht beißt du einen Spieler um ihn zu infizieren. Sind alle lebenden Spieler infiziert so gewinnst du. Ein infizierter Spieler weiß nicht, dass er infiziert ist und verhält sich demnach normal."])}
    },
    "wolfshundI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolfshund"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wolfshund sucht sich zu Beginn des Spiels aus, ob er ein Werwolf oder ein Dorfbewohner sein will. Dies macht er, indem er seine Augen in der ersten Werwolfphase entweder öffnet oder sie geschlossen lässt."])}
    },
    "engelI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engel"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaffst du es am ersten Tag gehängt zu werden, ist das Spiel vorbei und du gewinnst alleine. Schaffst du dies nicht wirst du ein ganz normaler Dorfbewohner."])}
    },
    "grabpluendererI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grab Plünderer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Grab Plünderer darf jede Nacht die Karte eines toten Spielers ansehen."])}
    },
    "intruderI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindringling"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eindringling wird von allen wie ein ganz normaler Dorfbewohner gesehen. Er gewinnt jedoch alleine, wenn nur noch er und genau ein Dorfbewohner (ein Spieler mit der Fraktion Dorfbewohner) am Leben sind. \n\n Die Werwölfe können den Eindringling nicht fressen. \n\n Er darf jeder Nacht bis zu zwei Spieler seiner Wahl (nicht sich selbst) vor dem Tot schützen (inkl. dem Hängen am Tag). \n\n Jede zweite Nacht darf er zusätzlich einen Spieler seiner Wahl töten."])}
    },
    "schattenwirkerI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schattenwirker"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schattenwirker ist ein Helfer der Werwölfe, welcher mit ihnen gewinnt, jedoch diese nicht kennt und auch nicht mit diesen aufwacht. \n Er kann nicht gefressen werden. \n\n Als ein Meister der dunklen Magie kann er jede Nacht einen Spieler (außer sich selbst) so verstärken, dass dieser am Tag durch das Hängen nicht stirbt."])}
    },
    "werwolfAltesteI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf-Ältester"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Werwolf-Älteste ist ein Werwolf, der nicht gefressen werden kann. \n\n Zusätzlich erwacht er nach den Werwölfen noch einmal gesondert und hat die Erlaubnis, das ausgewählte Werwolfopfer alleine noch einmal zu ändern."])}
    },
    "reuevollerSoldatI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reue-volle Soldat"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Reue-volle Soldat ist ein Dorfbewohner, der aufgrund seiner Widerstandsfähigkeit nicht gefressen werden kann.\n\nEr hat jedoch so viel Tod und Leid erlebt, dass er sich geschworen hat, nie wieder in den Tod eines unschuldigen Dorfbewohners verwickelt zu sein.\n\nEr stirbt, sollte er am Tag für einen Dorfbewohner gestimmt haben, der am Ende des Tages gehängt wird und dadurch stirbt."])}
    }
  },
  "ghostCardsI18n": {
    "echoGeistI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echo Geist"])},
      "kurzInfo": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf eingeschränkt kommunizieren"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf Nachts Augen öffnen"])}
      ],
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Echo Geist gewinnt und verliert noch immer mit seinem ursprünglichen Team. \nEr hat die Fähigkeit mit den lebenden Spieler zu kommunizieren und darf Nachts jederzeit die Augen öffnen. \n\nEr darf jedoch zu keiner Zeit sprechen, gestikulieren oder gar auf Andere deuten. \n\nEr kommuniziert ausschließlich über Worte die im Nachts vorgeschlagen werden"])}
    },
    "wahlGeistI18n": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahl Geist"])},
      "kurzInfo": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht kommunizieren"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachts Augen geschlossen halten"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf täglich anonym mit stimmen"])}
      ],
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wahl Geist gewinnt und verliert noch immer mit seinem ursprünglichen Team. Er darf bei der täglichen Abstimmung mit stimmen, seine Stimme hat aber einen geringeren Wert als der eines Lebenden. Wie hoch der Anteil deiner Stimme ist hängt von der Anzahl der Wahl Geister im Spiel ab. Ein Wahl Geist darf zu keiner Zeit mit Anderen kommunizieren und muss Nachts die augen geschlossen halten. \n\n 1-2 Wahl Geist : 1 \n 3-4 Wahl Geister : 0.5 \n 5-6 Wahl Geister : 0.35 \n 7+ Wahl Geister : 0.25"])}
    }
  },
  "ghostWords": {
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "gross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groß"])},
    "klein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klein"])},
    "dick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dick"])},
    "duenn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dünn"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang"])},
    "kurz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
    "blond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blond"])},
    "bruennett": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brünett"])},
    "schwarzhaarig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwarzhaarig"])},
    "lockig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lockig"])},
    "glatt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glatt"])},
    "kraus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraus"])},
    "blauaeugig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blauäugig"])},
    "gruenaeugig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grünaugig"])},
    "braunaeugig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Braunäugig"])},
    "sommersprossig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommersprossig"])},
    "bartlos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bartlos"])},
    "baertig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bärtig"])},
    "schnurrbaertig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnurrbärtig"])},
    "brillentragend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brillentragend"])},
    "brillenlos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brillenlos"])},
    "schmal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schmal"])},
    "breit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breit"])},
    "jugendlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugendlich"])},
    "reif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reif"])},
    "elegant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegant"])},
    "sportlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportlich"])},
    "gemuetlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemütlich"])},
    "modern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern"])},
    "traditionell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditionell"])},
    "charismatisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charismatisch"])},
    "sympathisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sympathisch"])},
    "ernst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ernst"])},
    "froehlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fröhlich"])},
    "verschmitzt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschmitzt"])},
    "schuechtern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüchtern"])},
    "selbstbewusst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstbewusst"])},
    "natuerlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natürlich"])},
    "geschminkt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschminkt"])},
    "ungekaemmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungekämmt"])},
    "ordentlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordentlich"])},
    "laessig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lässig"])},
    "schick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schick"])},
    "leger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leger"])},
    "extravagant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extravagant"])},
    "naturschoen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naturschön"])},
    "schlank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlank"])},
    "muskuloes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muskulös"])},
    "zierlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zierlich"])},
    "rot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rot"])},
    "blau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blau"])},
    "gruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grün"])},
    "gelb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelb"])},
    "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange"])},
    "violett": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violett"])},
    "rosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosa"])},
    "braun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Braun"])},
    "schwarz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwarz"])},
    "weiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiß"])},
    "grau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grau"])},
    "gold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
    "silber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silber"])},
    "tuerkis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkis"])},
    "magenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magenta"])},
    "kastanienbraun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kastanienbraun"])},
    "olivgruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olivgrün"])},
    "himmelblau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Himmelblau"])},
    "koralle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koralle"])},
    "limettengruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limettengrün"])},
    "beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
    "hellblau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hellblau"])},
    "dunkelblau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkelblau"])},
    "hellgruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hellgrün"])},
    "dunkelgruen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkelgrün"])},
    "hellbraun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hellbraun"])},
    "dunkelbraun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkelbraun"])},
    "hellgrau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hellgrau"])},
    "dunkelgrau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkelgrau"])},
    "zartrosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zartrosa"])},
    "halskette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halskette"])},
    "armband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armband"])},
    "ohrringe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohrringe"])},
    "ring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ring"])},
    "anhaenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänger"])},
    "uhr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uhr"])},
    "brosche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brosche"])},
    "haarspange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haarspange"])},
    "manschettenknoepfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manschettenknöpfe"])},
    "fusskette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußkette"])},
    "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Null"])},
    "eins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eins"])},
    "zwei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei"])},
    "drei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drei"])},
    "vier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vier"])},
    "fuenf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fünf"])},
    "sechs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sechs"])},
    "sieben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieben"])},
    "acht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acht"])},
    "neun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neun"])},
    "zehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zehn"])},
    "elf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elf"])},
    "zwoelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwölf"])},
    "dreizehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dreizehn"])},
    "vierzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierzehn"])},
    "fuenfzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fünfzehn"])},
    "sechzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sechzehn"])},
    "siebzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siebzehn"])},
    "achtzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtzehn"])},
    "neunzehn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neunzehn"])},
    "zwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwanzig"])},
    "einundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einundzwanzig"])},
    "zweiundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiundzwanzig"])},
    "dreiundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dreiundzwanzig"])},
    "vierundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierundzwanzig"])},
    "fuenfundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fünfundzwanzig"])},
    "sechsundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sechsundzwanzig"])},
    "siebenundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siebenundzwanzig"])},
    "achtundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtundzwanzig"])},
    "neunundzwanzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neunundzwanzig"])},
    "dreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dreißig"])},
    "einunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einunddreißig"])},
    "zweiunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiunddreißig"])},
    "dreiunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dreiunddreißig"])},
    "vierunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierunddreißig"])},
    "fuenfunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fünfunddreißig"])},
    "sechsunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sechsunddreißig"])},
    "siebenunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siebenunddreißig"])},
    "achtunddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtunddreißig"])},
    "neununddreissig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neununddreißig"])},
    "vierzig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierzig"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
    "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
    "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
    "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
    "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H"])},
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I"])},
    "j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])},
    "k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
    "n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
    "o": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T"])},
    "u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U"])},
    "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V"])},
    "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
    "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
    "z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
    "nebelverhangen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebelverhangen"])},
    "duester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düster"])},
    "schaurig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaurig"])},
    "geisterhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geisterhaft"])},
    "verlassen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlassen"])},
    "gespenstisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespenstisch"])},
    "unheimlich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unheimlich"])},
    "verwittert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwittert"])},
    "stille": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stille"])},
    "dunkel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkel"])},
    "schattenhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schattenhaft"])},
    "kalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalt"])},
    "mystisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mystisch"])},
    "gruselig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruselig"])},
    "abgelegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelegen"])},
    "gespensterhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespensterhaft"])},
    "schleierhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schleierhaft"])},
    "raetselhaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rätselhaft"])},
    "spukend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spukend"])},
    "einsam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einsam"])},
    "verfallen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfallen"])},
    "geheimnisvoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheimnisvoll"])},
    "nachtumhuellt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachtumhüllt"])},
    "verwunschen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwunschen"])},
    "schrecklich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrecklich"])},
    "atmosphaerisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmosphärisch"])},
    "gaensehaut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gänsehaut"])},
    "trueb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trüb"])},
    "geistervoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geistervoll"])},
    "erschreckend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erschreckend"])},
    "pizza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pizza"])},
    "hamburger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburger"])},
    "sushi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sushi"])},
    "spaghetti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaghetti"])},
    "currywurst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currywurst"])},
    "croissant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissant"])},
    "sauerbraten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauerbraten"])},
    "paella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paella"])},
    "tacos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tacos"])},
    "curry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curry"])},
    "kimchi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimchi"])}
  },
  "reduceVote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmkraft reduzieren (1)"])},
  "reduceVote05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmkraft reduzieren (0.5)"])},
  "powerVote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmkraft erhöhen (1)"])},
  "powerVote05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmkraft erhöhen (0.5)"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger anzeigen"])},
  "eat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fressen"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
  "shoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schießen"])},
  "becomeWolfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Werwolf werden"])},
  "showCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte anzeigen"])},
  "showFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktion anzeigen"])},
  "todesTrank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todestrank"])},
  "heilTrank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heiltrank"])},
  "todesTrank-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töten"])},
  "heilTrank-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heilen"])},
  "heal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heilen"])},
  "uebernachten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übernachten"])},
  "transformToWolf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Werwolf verwandeln"])},
  "twoVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Stimmen"])},
  "eatExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra fressen"])},
  "bless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnen"])},
  "protect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schützen"])},
  "saveFromHang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhängbar"])},
  "showAsVillager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Dorfbewohner anzeigen"])},
  "selectCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte auswählen"])},
  "love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verliebt"])},
  "blockVote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahl blockieren"])},
  "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfrieren"])},
  "killWolf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf töten"])},
  "restrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einschränken"])},
  "voteTwice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweimal abstimmen"])},
  "vampire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vampir"])},
  "kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töten"])},
  "getCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte erhalten"])},
  "vorbild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbild"])},
  "sacrifice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opfern"])},
  "errorInvalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail-Adresse"])},
  "errorRequiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "cardSavedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte erfolgreich gespeichert"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "registerWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldung"])},
  "registerViewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
  "loginViewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "loginWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "loginWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Google anmelden"])},
  "continueWithoutLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anmeldung fortsetzen"])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier klicken, um sich zu registrieren"])},
  "passwordOrEmailIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Passwort oder falsche E-Mail-Adresse"])},
  "userCreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erfolgreich erstellt"])},
  "filedRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])},
  "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail-Adresse"])},
  "sendResetEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen E-Mail senden"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "resetEmailSentSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine E-Mail zum Zurücksetzen wurde an Ihre E-Mail-Adresse gesendet. Sie könnte im Spam-Ordner sein."])},
  "restEmailFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden der Zurücksetzen-E-Mail fehlgeschlagen"])},
  "anonymousLoginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schiefgelaufen. Überprüf deine Internetverbindung und versuch es erneut."])},
  "continueOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline fortfahren"])},
  "offlineDisplayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer bin ich"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "inGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt für das Spiel"])},
  "notInGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht im Spiel"])},
  "createCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten"])},
  "cardDeck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deck"])},
  "cardStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Entwickler"])},
  "communityCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
  "createdByOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von anderen erstellt"])},
  "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorials"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "appSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Einstellungen"])},
  "defaultGameSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Spieleinstellungen"])},
  "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf App"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil aktualisieren"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "profileUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil erfolgreich aktualisiert"])},
  "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
  "passwordsDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
  "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 6 Zeichen lang sein"])},
  "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail wird bereits verwendet"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schiefgelaufen. Überprüf deine Internetverbindung und versuche es erneut."])},
  "userDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer ist deaktiviert"])},
  "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer nicht gefunden"])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Passwort"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch"])},
  "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])},
  "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesisch"])},
  "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisch"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanisch"])},
  "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinesisch"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreanisch"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisch"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkisch"])},
  "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polnisch"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "howStrongIsThatCardForTheVillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie stark ist diese Karte für das Dorf?"])},
  "aboutAsStrongAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwa so stark wie:"])},
  "DORFBEWOHNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorfbewohner"])},
  "WERWOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf"])},
  "ALLEINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielt allein"])},
  "SWITCHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorfbewohner-Werwolf-Mix"])},
  "VOR_AMOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor dem Amor"])},
  "NACH_AMOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Amor"])},
  "VOR_WERWOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor den Werwölfen"])},
  "NACH_WERWOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach den Werwölfen"])},
  "VOR_HEXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor der Hexe"])},
  "NACH_HEXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach der Hexe"])},
  "fraktion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktion"])},
  "exemplare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplare"])},
  "cardNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprache, in der du schreibst"])},
  "playWithDayRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagesrollen"])},
  "dayRoleSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl der Tagesrolle"])},
  "playWithDayRolesHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich zu den geheimen Nacht rollen erhält jeder Spieler eine Tagesrolle. Diese Tagesrollen sind für alle offen sichtbar. \nTagesrollen haben ähnlich wie die bereits bekannten Nacht rollen besondere Fähigkeiten, die während des Tages eingesetzt werden können.\n\nBeispiel: Maria könnte ein Werwolf sein und die Tagesrolle Priester erhalten. Jeder Spieler weiß, dass Maria eine Priesterin ist, aber nicht, dass sie auch ein Werwolf ist. Als Priesterin hat Maria die einmalige Fähigkeit, die Nachtrollenkarte eines beliebigen Spielers während des Tages anzusehen."])},
  "playWithGhostCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geisterkarten"])},
  "ghostCardSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl der Geisterkarte"])},
  "playWithGhostCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Geisterkarten sind spezielle Karten, die nur verwendet werden, wenn ein Spieler stirbt. Wenn ein Spieler stirbt, wird er zum Geist. Als Geist spielt der Spieler weiterhin das Spiel und muss die Runde nicht verlassen. Geister dürfen nicht mit den Lebenden kommunizieren, aber sie dürfen miteinander kommunizieren. Allerdings nur während des Tages und nur solange kein lebender Spieler sie hört. \n\nBeispiel: Wenn Maria - aus irgendeinem Grund - stirbt, könnte sie sich in einen Echo-Geist verwandeln. Als Echo-Geist hat Maria die Fähigkeit, mit den lebenden Spielern zu kommunizieren. Die Kommunikation ist jedoch stark eingeschränkt."])},
  "variableNrExemplare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable Anzahl von Exemplaren"])},
  "variableNrExemplareHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann der Spielleiter kontrollieren, wie viele Exemplare dieser Karte im Spiel sind? Ähnlich wie bei den Werwolf- oder Dorfbewohnerkarten?"])},
  "exemplareHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Exemplare dieser Karte gibt es im Spiel? Zum Beispiel haben die Schwestern immer 2 Exemplare und die Brüder immer 3 Exemplare im Spiel."])},
  "callAtNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Nacht aufwachen"])},
  "callAtNightHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wacht diese Karte in der Nacht auf? Zum Beispiel wachen die Werwölfe in der Nacht auf, aber die Dorfbewohner nicht."])},
  "callOnlyInTheFirstNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur in der ersten Nacht"])},
  "callOnlyInTheFirstNightHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wacht diese Karte nur in der ersten Nacht auf? Zum Beispiel wacht der Amor nur in der ersten Nacht auf, aber die Werwölfe wachen jede Nacht auf."])},
  "addActiveEffect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Effekt hinzufügen"])},
  "addPassiveEffect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive Effekt hinzufügen"])},
  "NORMAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "KANN_NICHT_GEFRESSEN_WERDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht gefressen werden"])},
  "UNSTERBLICH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsterblich"])},
  "sterblichkeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterblichkeit"])},
  "sterblichkeitHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist es unmöglich, diesen Charakter von den Werwölfen fressen zu lassen oder sogar unsterblich?"])},
  "sterblichkeitsdauer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer der Sterblichkeit"])},
  "sterblichkeitsdauerHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie lange dauert der Effekt der Unsterblichkeit?"])},
  "EINMALIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalig"])},
  "DAUERHAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauerhaft"])},
  "ZWEIMAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweimal"])},
  "DREIMAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dreimal"])},
  "WAEHREND_KARTE_IM_SPIEL_IST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während die Karte im Spiel ist"])},
  "WAEHREND_KARTE_AM_LEBEN_IST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während die Karte lebt"])},
  "WAEHREND_KARTE_TOT_IST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während die Karte tot ist"])},
  "BIS_KARTE_STIRBT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis die Karte stirbt"])},
  "BIS_ERSTE_KARTE_EINER_FRAKTION_STIRBT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis die erste Karte einer Fraktion stirbt"])},
  "manipulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manipulation"])},
  "manipulationFalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls"])},
  "NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
  "ALS_ANDERE_ROLLE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als andere Rolle anzeigen"])},
  "ALS_ANDERE_FRAKTION_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als andere Fraktion anzeigen"])},
  "ALS_ANDERE_KARTE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine andere Karte anzeigen"])},
  "zuWelcherFraktion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu welcher Fraktion?"])},
  "reallyWantToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Karte wirklich löschen?"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "CreateGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel erstellen"])},
  "aktivierFrequenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierungsfrequenz"])},
  "aktivierFrequenzHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie oft kann dieser Effekt verwendet werden?"])},
  "dauer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
  "dauerHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie lange dauert dieser Effekt?"])},
  "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
  "possibleTargets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mögliche Ziele"])},
  "possibleTargetsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer kann von diesem Effekt als Ziel ausgewählt werden?"])},
  "numberOfTargets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Ziele"])},
  "numberOfTargetsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Spieler müssen von diesem Effekt als Ziel ausgewählt werden? Zum Beispiel muss der Amor 2 Spieler als Ziel auswählen."])},
  "zweiMalDenGleichen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleicher Spieler zweimal hintereinander"])},
  "zweiMalDenGleichenHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist es möglich, den gleichen Spieler in zwei aufeinanderfolgenden Nächten als Ziel auszuwählen, oder muss mindestens eine Nacht dazwischen liegen, in der jemand anders ausgewählt wird?"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "aktion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "aktionHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll eine Aktion durch den Effekt ausgeführt werden, oder reicht es aus, dass der Spielleiter sieht, dass der ausgewählte Spieler von diesem Effekt betroffen ist?"])},
  "stirbtBeiAnwendung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stirbt bei Anwendung"])},
  "stirbtBeiAnwendungHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stirbt diese Karte, wenn sie diesen Effekt verwendet?"])},
  "JEDE_NACHT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jede ", _interpolate(_named("state"))])},
  "JEDE_ZWEITE_NACHT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jede zweite ", _interpolate(_named("state"))])},
  "JEDE_DRITTE_NACHT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jede dritte ", _interpolate(_named("state"))])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacht"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächte"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
  "ZWEIMALIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweimalig"])},
  "DREIMALIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dreimalig"])},
  "BIS_NAECHSTE_NACHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis zur nächsten Nacht"])},
  "BIS_NAECHSTEN_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis zum nächsten Tag"])},
  "ZWEI_TAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei Tage"])},
  "DREI_TAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drei Tage"])},
  "GANZE_SPIEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ganze Spiel"])},
  "ZWEI_NAECHTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei Nächste"])},
  "DREI_NAECHTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drei Nächte"])},
  "ALLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Spieler"])},
  "LEBENDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebende Spieler"])},
  "assignCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte zuweisen"])},
  "TOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tote Spieler"])},
  "WERWOLF_OPFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opfer der Werwölfe"])},
  "ALLE_STERBENDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Spieler, die in dieser Nacht sterben würden"])},
  "NUR_SICH_SELBST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur sich selbst"])},
  "ALLE_AUSSER_SICH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Spieler außer sich selbst"])},
  "LEBENDE_AUSSER_SICH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebende Spieler außer sich selbst"])},
  "KEINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
  "KARTE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte des Ziels anzeigen"])},
  "KARTE_ZUWEISEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dem Ziel eine bestimmte Karte zuweisen"])},
  "NEUE_KARTE_AUS_ALLEN_GEBEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dem Ziel eine Karte aus allen Karten in Ihrem Deck zuweisen"])},
  "NEUE_KARTE_AUS_UEBRIGEN_GEBEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dem Ziel eine Karte aus unzugewiesenen Karten im Spiel zuweisen"])},
  "FRAKTION_WECHSELN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fraktion des Ziels ändern"])},
  "FRAKTION_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktion des Ziels anzeigen"])},
  "ROLLE_ZEIGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakter des Ziels anzeigen (Fraktion wird nicht angezeigt)"])},
  "STIRBT_NICHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "STIRBT_FALLS_FRAKTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, falls das Ziel eine Fraktion hat"])},
  "STIRBT_FALLS_KARTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, falls das Ziel einen Charakter hat"])},
  "STIRBT_IMMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, immer"])},
  "killOrSaveTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tötet/rettet"])},
  "killOrSaveTargetHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tötet oder rettet dieser Effekt das Ziel?"])},
  "NICHTS_TUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Veränderungen"])},
  "TOETEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tötet"])},
  "VOR_WERWOLF_RETTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettet vor den Werwölfen"])},
  "VOR_TOT_BEWAHREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettet vor dem Tod in der Nacht"])},
  "VOR_TOT_BEWAHREN_INKLUSIVE_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettet vor dem Tod (einschließlich Tag)"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "nightActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachtaktivität"])},
  "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
  "EAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als gegessen markieren"])},
  "FALL_IN_LOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verliebt"])},
  "effects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effekte"])},
  "nameSuccessfullyChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name erfolgreich geändert"])},
  "nameChangedFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung des Namens fehlgeschlagen"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "activeEffects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Effekte"])},
  "passiveEffects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive Effekte"])},
  "saveCardLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte speichern"])},
  "publishCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte online veröffentlichen"])},
  "clearCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte leeren"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeren"])},
  "deathBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tod durch"])},
  "deathByHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie stirbt diese Karte, wenn der Effekt verwendet wird?"])},
  "KILLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Nacht getötet"])},
  "EATEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Ziel der Werwölfe markiert"])},
  "DAILY_VOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während der täglichen Abstimmung getötet"])},
  "clearCardConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Karte wirklich leeren?"])},
  "reallyWantToDeleteConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Effekt wirklich löschen?"])},
  "removeCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte entfernen"])},
  "removeCardConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Karte wirklich entfernen?"])},
  "removeCardOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte entfernen"])},
  "saveEditCardLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "cardEditSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartenänderungen wurden gespeichert"])},
  "editCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte bearbeiten"])},
  "whenShouldThisCardBeCalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann soll diese Karte aufgerufen werden?"])},
  "cardPublishedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte erfolgreich veröffentlicht"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
  "basicKarte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis Karte"])},
  "basicKarteHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist dies eine grundlegende Karte?"])},
  "errorLoadingBasicCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden der grundlegenden Karten"])},
  "deleteCardFromCloudConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Karte wirklich aus der Cloud löschen?"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte löschen"])},
  "cardDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte gelöscht"])},
  "yourMitgliedschaftWasUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Mitgliedschaft wurde aktualisiert"])},
  "errorUpdatingYourMitgliedschaft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Aktualisieren Ihrer Mitgliedschaft"])},
  "paymentWasCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlung wurde abgebrochen"])},
  "errorDuringPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler während der Zahlung"])},
  "MARK_AS_WEREWOLF_TARGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Ziel der Werwölfe markieren"])},
  "KANN_NICHT_GELYNCHT_WERDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann während des Tages nicht gehängt werden"])},
  "createCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopie erstellen"])},
  "showDeadPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige tote Spieler"])},
  "showDeadPlayersHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollen tote Spieler in der Spielerliste angezeigt werden?"])},
  "showFirstNightAlways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer erste Nacht anzeigen"])},
  "showFirstNightAlwaysHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollen die Karten, die du nur in der ersten Nacht aufrufst, immer angezeigt werden?"])},
  "nightPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachtphase"])},
  "nightPhaseHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Karten möchtest du nachts aufrufen?\n\nAlle: Alle Karten in deinem Deck\nAusgewählte Karten: Karten, die du für das Spiel ausgewählt hast\nIm Spiel: Nur Karten, die derzeit im Spiel sind (zeigt auch Karten von toten Spielern)\nNur notwendige: Nur Karten, die gerade erforderlich sind"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "SELECTED_CARDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Karten"])},
  "ONLY_NECESSARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur notwendige"])},
  "IN_PLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Spiel"])},
  "IN_PLAY_AND_EXTRA_CARDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Spiel und zusätzliche Karten"])},
  "godMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottmodus"])},
  "godModeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Gottmodus kannst du Karten von Spielern ändern, Spieler neu anordnen und vieles mehr."])},
  "joinGameOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel von"])},
  "numberOfPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Spieler"])},
  "numberOfCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Karten"])},
  "balancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbalancieren"])},
  "PlaygroundOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel (online)"])},
  "PlaygroundOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel (offline)"])},
  "selectRandomCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufällige Karten auswählen"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel"])},
  "ScanToJoinGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum beitreten scannen"])},
  "closeGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel schließen"])},
  "closeGameConfirmDialogText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du dieses Spiel wirklich schließen?"])},
  "gameIsRunning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel läuft"])},
  "gameStopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel gestoppt"])},
  "JoinGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel beitreten"])},
  "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwolf App"])},
  "noCardYetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Karte"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
  "GameSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieleinstellungen"])},
  "numberOfExtraCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl zusätzlicher Karten"])},
  "numberOfExtraCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du angeben, wie viele Karten zur Erweiterung der Anzahl der Spieler dem Spiel hinzugefügt werden sollen. \n\nWenn du beispielsweise 5 Spieler und 2 zusätzliche Karten hast, werden 7 Karten zufällig aus dem Deck ausgewählt."])},
  "CreateCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte erstellen"])},
  "CardDeck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten"])},
  "CommunityCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community-Karten"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "Tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitungen"])},
  "AppSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Einstellungen"])},
  "joinAGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel beitreten"])},
  "createAGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel erstellen"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "onOneDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf einem Gerät"])},
  "onMultipleDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf mehreren Geräten"])},
  "joiningGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielbeitritt..."])},
  "showPlayersSelectedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige ausgewählte Karten der Spieler"])},
  "showPlayersSelectedCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollen die Spieler die Karten sehen können, die du für dieses Spiel ausgewählt hast?"])},
  "showExemplare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige Exemplare"])},
  "showExemplareHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollen die Spieler sehen können, wie viele Karten einer Art im Spiel sind?"])},
  "addPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler hinzufügen"])},
  "showAllCardsToPlayersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige Karten den Spielern"])},
  "showAllCardsToPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige allen Spielern die Karten"])},
  "showAllCardsToPlayersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib dieses Handy den Spielern, die ohne Handy spielen, damit sie ihre Karten sehen können."])},
  "passPhoneTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gib das Handy weiter an ", _interpolate(_named("name"))])},
  "holdToSeeCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halte, um deine Karte zu sehen"])},
  "nextPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster Spieler"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "allPlayerSawThereCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Spieler haben ihre Karten gesehen. Du kannst diesen Dialog schließen und das Spiel fortsetzen."])},
  "passToGameMaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib dem Spielleiter"])},
  "nextNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Nacht"])},
  "hasI18nKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i18n-Schlüssel"])},
  "hasI18nKeyHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll diese Karte einen i18n-Schlüssel haben?"])},
  "i18nKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i18n-Schlüssel"])},
  "viewSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Ansicht"])},
  "nightSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächte"])},
  "addons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
  "deckSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten"])},
  "playerViewSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieleransicht"])},
  "minOneSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Auswahl"])},
  "minOneSelectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst mindestens 1 auswählen"])},
  "GOT_EATEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wurde gefressen"])},
  "become": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werde"])},
  "youAreDead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist tot"])},
  "ALWAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer"])},
  "GOT_KILLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wurde getötet"])},
  "HANG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehängt"])},
  "ASSIGN_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte zuweisen"])},
  "dorfWon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorf hat gewonnen"])},
  "werewolvesWon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werwölfe haben gewonnen"])},
  "playerWon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("playerName")), " hat gewonnen"])},
  "selfTargetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann sich selbst als Ziel wählen"])},
  "clickToShowCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "clickToShowGhostCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geisterkarte"])},
  "clickToShowDayCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Rolle"])},
  "clickToShowGhostWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geisterworte"])},
  "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahl"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anzeigen"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbergen"])},
  "selectCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten auswählen"])},
  "selfTargetableHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann dieser Karteneffekt auf sich selbst angewendet werden?"])},
  "successfullySendToGm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeichert"])},
  "failedToSendToGm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern fehlgeschlagen"])},
  "loadingDataFromServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Daten vom Server..."])},
  "selectAWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle ein Wort aus"])},
  "startVote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstimmung starten"])},
  "voteForPlayerToDie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer soll gehängt werden?"])},
  "votingResultOfDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abstimmung Tag ", _interpolate(_named("day"))])},
  "calculating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen..."])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
  "votingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstimmungsergebnisse"])},
  "showCardsOfDeadPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten von toten Spielern anzeigen"])},
  "showCardsOfDeadPlayersHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollen die Karten von toten Spielern den Spielern angezeigt werden? Sie werden die Karten der toten Spieler neben deren Namen in der Spielerliste sehen. \n (Genau wie du die Spielerliste siehst)"])},
  "youWereKicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wurdest aus dem Spiel geworfen"])},
  "youLeftTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast das Spiel verlassen"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge"])},
  "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximale Länge beträgt ", _interpolate(_named("maxLength")), "!"])},
  "churchWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirchenmauer"])},
  "showNrOfWerewolves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige Anzahl der Werwölfe"])},
  "showNrOfWerewolvesHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll die ungefähre Anzahl der Werwölfe (+-1) den Spielern angezeigt werden? \n\n Nützlich, wenn du die Karten von toten Spielern nicht zeigst."])},
  "approximatedNrOfWolfes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl lebender Werwölfe"])},
  "approximated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ungefähr)"])},
  "wirteSomething": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibe etwas"])},
  "churchWallDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag ", _interpolate(_named("day"))])},
  "village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorf"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
  "openMainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü öffnen"])},
  "church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirche"])},
  "townHall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rathaus"])},
  "churchWallHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kirchenmauer ist ein zusätzlicher Tab für die Spieler. Dort haben sie eine Mauer, auf der jeder lebende Spieler jeden Tag anonym etwas schreiben kann."])},
  "churchWallPlayerViewHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Kirchenmauer kannst du jeden Tag anonym etwas schreiben und sehen, was andere Spieler geschrieben haben."])},
  "possibleCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mögliche Karten"])},
  "votingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahl Zeit"])},
  "votingTimeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie lange haben die Spieler Zeit, um abzustimmen?"])},
  "churchWallMaxCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirchenmauer max. Zeichen"])},
  "churchWallMaxCharactersHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Zeichen dürfen die Spieler auf der Kirchenmauer jeden Tag schreiben?"])},
  "kickPlayerFromGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick Spieler"])},
  "manipulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manipulieren"])},
  "showOriginalFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktion anzeigen (ohne Effekte)"])},
  "showOriginalCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karate anzeigen (ohne Effekte)"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "voteValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahlwert"])},
  "noVotingResultsYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab noch keine Abstimmung"])},
  "youCantVote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst nicht abstimmen"])},
  "enthaltungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthaltungen"])},
  "BASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
  "ADVANCED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterung"])},
  "FROM_OTHERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von anderen"])},
  "OWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene"])},
  "gameWasClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel wurde geschlossen"])},
  "ghostChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installieren"])},
  "install_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installiere Werwolf als App"])},
  "defaultGameSettingsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Einstellungen werden für jedes Spiel, dass du erstellst, als Vorlage verwendet"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
  "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
  "readPrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Datenschutzerklärung gelesen und akzeptiere diese."])},
  "STIMMRECHT_ANPASSEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmrecht anpassen (Wert)"])},
  "votingValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahlwert"])},
  "votingValueHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um wie viel soll der Wert der Stimme geändert werden? z.B.: Ein Wert von -0.25 macht das dieser Spieler bei der täglichen Abstimmung nur noch mit 0.75 statt mit dem Standardwert von 1 abstimmen kann."])},
  "minutesAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])},
  "secondsAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekunden"])},
  "hoursAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
  "swipteThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wisch Sensitivität"])},
  "waitingForPlayersToJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte auf Spieler"])},
  "waitingForPlayersToJoinInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte auf Spieler, die dem Spiel beitreten. In der Zwischenzeit kannst du die Karten auswählen und Einstellungen vornehmen."])},
  "manuelAddPlayerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls ein Spieler kein Smartphone hat oder nicht auf seinem Smartphone spielen will kannst du ihn manuell hinzufügen."])},
  "filterByNameOrId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Name oder ID filtern"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "youWereDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wurdest abgelehnt"])},
  "waitingForAcceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte das du akzeptiert wirst"])},
  "playersWantToJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler Anfragen"])},
  "becauseAnonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle ein Konto, um diese Funktion zu nutzen"])},
  "anonymousUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymes Konto"])},
  "anonymousUserDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist derzeit anonym angemeldet. Dieses Konto wird automatisch nach 30 Tagen gelöscht. Außerdem stehen dir nicht alle Funktionen der App zur Verfügung. Wenn dir die App gefällt, erstelle ein Konto oder meld dich mit Google an."])},
  "startDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starte Tag"])},
  "startNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starte Nacht"])},
  "stopGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel beenden"])},
  "playerWithMostVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler mit den meisten Stimmen"])},
  "noOneGetsHanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niemand wird gehängt"])},
  "addPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler hinzufügen"])},
  "addPlayersInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge mindestens 5 Spieler zum Spiel hinzu. Diese Spieler benötigen kein Smartphone."])},
  "addPlayersInfoPassAround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Spielstart fordert die App dich auf, das Handy herumzureichen, damit jeder Spieler seine Karte sehen kann."])},
  "nrAliveWerwolfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl lebender Werwölfe (inkl. Helfer)"])},
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigename"])},
  "install_description_ios_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installiere Werwolf als App auf deinem iPhone: tippe auf"])},
  "install_description_ios_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und dann auf"])},
  "install_description_ios_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Home-Bildschirm"])},
  "youAreOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist offline"])},
  "youAreOfflineDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst die App weiterhin verwenden, aber du kannst ausschließlich offline Spiele erstellen"])},
  "offlineGameDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei einem Offline-Spiel musst du das Handy herumreichen, damit jeder Spieler seine Karte sehen kann. Die App wird dich auffordern, das Handy herumzureichen, wenn das Spiel beginnt."])},
  "createOfflineGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline Spiel erstellen"])},
  "possibleUnassignedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mögliche freie Karten"])},
  "showPlayersExtraVillagerCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Dorfbewohner-Karten anzeigen"])},
  "showPlayersExtraVillagerCardsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollen die Spieler die zusätzlichen Dorfbewohner-Karten sehen? Diese Karten sind nicht im Spiel, werden aber den Spielern angezeigt."])},
  "showWerwolfsOpenRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Rollen den Werwölfe anzeigen"])},
  "showWerwolfsOpenRolesHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du diese Option aktivierst, sehen die Werwölfe, welche zusätzlichen Dorfbewohner-Karten vorhanden sind. Dadurch wird es für sie einfacher, über ihre Rolle zu lügen. \n\n Ihnen werden alle zusätzlichen Karten sowie X belegte Karten angezeigt. Dadruch können diese nicht sicher wissen Welche Karten frei und welche belegt sind. Sie bekommen nur einen Indiz.  \n\n X ist die abgerundete Hälfte der zusätzlichen Karten."])},
  "playerCircleView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreis-Ansicht"])},
  "dragPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsortieren"])},
  "hideFabCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz ausblenden"])},
  "playerCircleSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreisgröße der Spieler"])},
  "playerCircleRound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rundung"])},
  "cardsPlayerView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartenansicht (Spieler)"])},
  "dead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (TOT)"])},
  "placeHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzhalter"])},
  "votingSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstimmung"])},
  "voteDuringDayTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchgehend abstimmen"])},
  "voteDuringDayTimeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn diese Option aktiviert ist, können die Spieler den ganzen Tag über abstimmen, während das Spiel läuft, ohne auf eine spezielle Aufforderung von dir warten zu müssen. \n\n Die Spieler haben Einblick in die aktuellen Abstimmungen, sodass sie sehen können, wer für wen gestimmt hat. Die Stimmen der Geister (falls vorhanden) werden verdeckt gehalten und erst sichtbar gemacht, wenn du die Wahl für den Tag beendest. \n\n Wenn diese Option deaktiviert ist, können die Spieler nur abstimmen, wenn du explizit die Abstimmung startest. Sie haben keine Möglichkeit, außerhalb dieser speziellen Abstimmungszeit zu stimmen."])},
  "reopenVoteForToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahlzeit neu eröffnen"])},
  "stopVoteForToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahlzeit beenden"])},
  "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten"])},
  "noMessagesYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Nachrichten"])},
  "newMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Nachrichten"])},
  "toggleToShowCardHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte anzeigen"])},
  "toggleToShowCardDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippe auf den Schieberegler ("])},
  "toggleToShowCardDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["), um deine Karte anzuzeigen."])},
  "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufällig"])},
  "btnSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe der Buttons"])},
  "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klein"])},
  "large": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groß"])},
  "cantChooseThisPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Spieler kannst du nicht auswählen"])}
}