import { defineStore } from "pinia";
import { MyUser } from "@/models/MyUser";
import { Karte } from "@/models/Karte";
import { Language } from "@/models/enums/Language";
import { AppSettings } from "@/models/AppSettings";
import { Fraktion } from "@/models/enums/Fraktion";
import { ZeitpunktZumAufrufen } from "@/models/enums/ZeitpunktZumAufrufen";
import { Prices } from "@/models/Prices";
import { GameSettings } from "@/models/GameSettings";
import { NightPhase } from "@/models/enums/NightPhase";
import { AppState } from "@/models/AppState";
import { Game } from "@/models/Game";
import { GameState } from "@/models/enums/GameState";
import { DayRoleCard } from "@/models/DayRoleCard";
import { GhostCard } from "@/models/GhostCard";
import { cardService } from "@/services/card.service";
import { PlayerCircle } from "@/models/PlayerCircle";

const STORAGE_KEY_USER = "user";
const STORAGE_KEY_CARDS = "cards";
const STORAGE_KEY_DAY_CARDS = "day-cards";
const STORAGE_KEY_APP_SETTINGS = "appSettings";
const STORAGE_KEY_APP_PRICES = "appPrices";
const STORAGE_KEY_DEFAULT_GAME_SETTINGS = "defaultGameSettings";
const STORAGE_KEY_APP_STATE = "appState";
const STORAGE_KEY_GHOST_CARDS = "ghostCards";
const STORAGE_KEY_TAB = "tab";
export const useUserStore = defineStore(STORAGE_KEY_USER, {
  state: () => ({
    user: null as MyUser | null,
  }),
  getters: {
    getUser(): MyUser | null {
      return this.user;
    },
  },
  actions: {
    setUser(user: MyUser) {
      this.user = user;
      localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(user));
    },
    updateUser(updatedUser: MyUser) {
      if (
        updatedUser.mitgliedschaft != null &&
        updatedUser.emailVerified != null &&
        updatedUser.isAnonymous != null &&
        updatedUser.displayName != null &&
        updatedUser.lastGamesJoined != null &&
        updatedUser.uid != null
      ) {
        this.user = updatedUser;
        localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(updatedUser));
      } else {
        console.error("Ungültige Aktualisierungsdaten für den Benutzer");
      }
    },
    removeUser() {
      this.user = null;
      localStorage.removeItem(STORAGE_KEY_USER);
    },
    initializeUser() {
      const user = localStorage.getItem(STORAGE_KEY_USER);
      if (user) {
        this.user = JSON.parse(user);
      }
    },
  },
});

export const useCardStore = defineStore(STORAGE_KEY_CARDS, {
  state: () => ({
    cards: [] as Karte[],
  }),
  getters: {
    getCards(): Karte[] {
      return this.cards;
    },
  },
  actions: {
    reset() {
      this.cards = [];
      localStorage.removeItem(STORAGE_KEY_CARDS);
    },
    setCards(cards: Karte[]) {
      this.cards = cards;
      localStorage.setItem(STORAGE_KEY_CARDS, JSON.stringify(cards));
    },
    addCard(card: Karte) {
      this.cards.push(card);
      localStorage.setItem(STORAGE_KEY_CARDS, JSON.stringify(this.cards));
    },
    updateCard(updatedCard: Karte) {
      const index = this.cards.findIndex((card) => card.id === updatedCard.id);
      if (index !== -1) {
        this.cards.splice(index, 1, updatedCard);
        localStorage.setItem(STORAGE_KEY_CARDS, JSON.stringify(this.cards));
      } else {
        console.error("Karte nicht gefunden");
      }
    },
    removeCard(cardId: string) {
      const index = this.cards.findIndex((card) => card.id === cardId);
      if (index !== -1) {
        this.cards.splice(index, 1);
        localStorage.setItem(STORAGE_KEY_CARDS, JSON.stringify(this.cards));
      } else {
        console.error("Karte nicht gefunden");
      }
    },
    initializeCards() {
      const cards = localStorage.getItem(STORAGE_KEY_CARDS);
      if (cards) {
        this.cards = JSON.parse(cards);
      }
    },
    getCardsAsStrongAs(rating: number): Karte[] {
      return this.cards.filter((card) => card.rating === rating);
    },
    getCard(id: string): Karte | undefined {
      return this.cards.find((card) => card.id === id);
    },
    getCardByI18nKey(i18nKey: string): Karte | undefined {
      return this.cards.find((card) => card.i18nKey === i18nKey);
    },
  },
});

export const useDayCardStore = defineStore(STORAGE_KEY_DAY_CARDS, {
  state: () => ({
    cards: [] as DayRoleCard[],
  }),
  getters: {
    getCards(): DayRoleCard[] {
      return this.cards;
    },
  },
  actions: {
    reset() {
      this.cards = [];
      localStorage.removeItem(STORAGE_KEY_DAY_CARDS);
    },
    setCards(cards: DayRoleCard[]) {
      this.cards = cards;
      localStorage.setItem(STORAGE_KEY_DAY_CARDS, JSON.stringify(cards));
    },
    addCard(card: DayRoleCard) {
      this.cards.push(card);
      localStorage.setItem(STORAGE_KEY_DAY_CARDS, JSON.stringify(this.cards));
    },
    updateCard(updatedCard: DayRoleCard) {
      const index = this.cards.findIndex((card) => card.id === updatedCard.id);
      if (index !== -1) {
        this.cards.splice(index, 1, updatedCard);
        localStorage.setItem(STORAGE_KEY_DAY_CARDS, JSON.stringify(this.cards));
      } else {
        console.error("DayRoleCard nicht gefunden");
      }
    },
    removeCard(cardId: string) {
      const index = this.cards.findIndex((card) => card.id === cardId);
      if (index !== -1) {
        this.cards.splice(index, 1);
        localStorage.setItem(STORAGE_KEY_DAY_CARDS, JSON.stringify(this.cards));
      } else {
        console.error("DayRoleCard nicht gefunden");
      }
    },
    initializeCards() {
      const cards = localStorage.getItem(STORAGE_KEY_DAY_CARDS);
      if (cards) {
        this.cards = JSON.parse(cards);
      }
    },
    getCard(id: string): DayRoleCard | undefined {
      return this.cards.find((card) => card.id === id);
    },
    getCardByI18nKey(i18nKey: string): DayRoleCard | undefined {
      return this.cards.find((card) => card.i18nKey === i18nKey);
    },
  },
});

export const emptyCard: Karte = {
  id: "",
  name: "",
  beschreibung: "",
  fraktion: Fraktion.NONE,
  variableAnzahlExemplare: false,
  hasI18nKey: true,
  i18nKey: "emptyCardI18n",
  createdBy: "",
  exemplare: 1,
  rating: 0,
  createdByAdmin: true,
  nachtsAufrufen: false,
  basicKarte: false,
  nurInDerErstenNachtAufrufen: false,
  wannNachtsAufrufen: ZeitpunktZumAufrufen.NACH_WERWOLF,
  aktiveEffekte: [],
  passiveEffekte: [],
};

export const emptyDayCard: DayRoleCard = {
  id: "",
  name: "",
  beschreibung: "",
  variableAnzahlExemplare: false,
  hasI18nKey: false,
  i18nKey: "emptyCardI18n",
  createdBy: "",
  exemplare: 1,
  createdByAdmin: true,
  basicKarte: false,
  aktiveEffekte: [],
  passiveEffekte: [],
};
export const useCreateStore = defineStore("create", {
  state: () => ({
    card: JSON.parse(JSON.stringify(emptyCard)) as Karte,
    dayCard: JSON.parse(JSON.stringify(emptyDayCard)) as DayRoleCard,
  }),
  getters: {
    getCard(): Karte | DayRoleCard {
      return this.card;
    },
    getClearedCard(): Karte {
      this.card.hasI18nKey = false;
      this.card.i18nKey = "";
      this.card.fraktion = Fraktion.DORFBEWOHNER;
      return this.card;
    },
    getClearedDayCard(): DayRoleCard {
      this.dayCard.hasI18nKey = false;
      this.dayCard.i18nKey = "";
      return this.dayCard;
    },
  },
  actions: {
    reset() {
      this.card = JSON.parse(JSON.stringify(emptyCard));
    },
    updateCard(updatedCard: Karte | DayRoleCard) {
      if (this.card && this.card.id === updatedCard.id) {
        if (cardService.isNightRoleCard(updatedCard)) {
          this.card = updatedCard as Karte;
        } else {
          this.dayCard = updatedCard;
        }
      } else {
        console.error("Karte nicht gefunden");
      }
    },
    clearCard() {
      this.card = JSON.parse(JSON.stringify(emptyCard));
      this.dayCard = JSON.parse(JSON.stringify(emptyDayCard));
    },
  },
});

export const useAppSettingsStore = defineStore(STORAGE_KEY_APP_SETTINGS, {
  state: () => ({
    settings: {
      language: Language.GERMAN,
      swipeThreshold: 8,
      playerCircleSize: 17,
      playerCirclePercentage: 40,
      size: "lg",
      textBody: "text-body1",
    },
  }),
  getters: {
    getAppSettings(): AppSettings {
      return this.settings;
    },
  },
  actions: {
    reset() {
      this.settings = {
        language: Language.GERMAN,
        swipeThreshold: 8,
        playerCircleSize: 17,
        playerCirclePercentage: 40,
        size: "lg",
        textBody: "text-body1",
      };
      localStorage.removeItem(STORAGE_KEY_APP_SETTINGS);
    },
    setAppSettings(settings: AppSettings) {
      this.settings = settings;
      localStorage.setItem(STORAGE_KEY_APP_SETTINGS, JSON.stringify(settings));
    },
    updateAppSettings(updatedSettings: AppSettings) {
      this.settings = { ...this.settings, ...updatedSettings };
      localStorage.setItem(
        STORAGE_KEY_APP_SETTINGS,
        JSON.stringify(this.settings)
      );
    },
    save() {
      localStorage.setItem(
        STORAGE_KEY_APP_SETTINGS,
        JSON.stringify(this.settings)
      );
    },
    initializeAppSettings() {
      const settings = localStorage.getItem(STORAGE_KEY_APP_SETTINGS);
      if (settings) {
        this.settings = JSON.parse(settings);
      }
    },
  },
});

export const usePriceStore = defineStore(STORAGE_KEY_APP_PRICES, {
  state: () => ({
    prices: {
      basic: {
        one: 0,
        three: 0,
        twelve: 0,
      },
      plus: {
        one: 0,
        three: 0,
        twelve: 0,
      },
    },
  }),
  getters: {
    getPrices(): Prices {
      return this.prices;
    },
  },
  actions: {
    updatePrices(updatedPrices: Partial<Prices>) {
      this.prices = { ...this.prices, ...updatedPrices };
    },
  },
});

export const useDefaultGameSettingsStore = defineStore(
  STORAGE_KEY_DEFAULT_GAME_SETTINGS,
  {
    state: () => ({
      defaultGameSettings: {
        showDeadPlayers: true,
        showFirstNightAlways: false,
        nightPhase: NightPhase.ONLY_NECESSARY,
        extraNightPhasesCards: [],
        godMode: false,
        numberOfExtraCards: 7,
        showPlayersSelectedCards: true,
        showNrOfWerewolves: true,
        showCardsOfDeadPlayers: false,
        showExemplare: false,
        playWithDayRoles: false,
        votingTime: 15,
        churchWallMaxCharacters: 50,
        dayRoles: [],
        playWithChurchWall: false,
        playWithGhostCards: false,
        showPlayersExtraVillagerCards: false,
        showWerwolfsOpenRoles: false,
        ghostCards: [],
        voteDuringDayTime: false,
      } as GameSettings,
    }),
    getters: {
      getDefaultGameSettings(): GameSettings {
        return this.defaultGameSettings;
      },
    },
    actions: {
      reset() {
        this.defaultGameSettings = {
          showDeadPlayers: false,
          showFirstNightAlways: false,
          nightPhase: NightPhase.ONLY_NECESSARY,
          extraNightPhasesCards: [],
          godMode: false,
          numberOfExtraCards: 7,
          showPlayersSelectedCards: true,
          showNrOfWerewolves: true,
          showCardsOfDeadPlayers: false,
          showExemplare: false,
          playWithDayRoles: false,
          votingTime: 15,
          churchWallMaxCharacters: 50,
          dayRoles: [],
          playWithChurchWall: false,
          playWithGhostCards: false,
          showPlayersExtraVillagerCards: false,
          showWerwolfsOpenRoles: false,
          ghostCards: [],
          voteDuringDayTime: false,
        };
        localStorage.removeItem(STORAGE_KEY_DEFAULT_GAME_SETTINGS);
      },
      save() {
        localStorage.setItem(
          STORAGE_KEY_DEFAULT_GAME_SETTINGS,
          JSON.stringify(this.defaultGameSettings)
        );
      },
      initializeDefaultGameSettings() {
        const settings = localStorage.getItem(
          STORAGE_KEY_DEFAULT_GAME_SETTINGS
        );
        if (settings) {
          this.defaultGameSettings = JSON.parse(settings);
        }
      },
    },
  }
);

export function getEmptyGame(
  id: string,
  defaultGameSettings: GameSettings,
  isOffline: boolean
): Game {
  return {
    id: id,
    cards: [],
    players: [],
    kickedPlayerIds: [],
    wantToJoin: [],
    lastModified: new Date(),
    state: GameState.CREATED,
    activeCardId: "",
    activeDayCardId: "",
    activeCardEffectName: "",
    settings: defaultGameSettings,
    nightCount: 1,
    voteWasStartedToday: false,
    isOffline: isOffline,
    randomBinary: 0,
    votingIsRunning: false,
    votingEndForToday: false,
    votingStartTime: 0,
    dayRoleCards: [],
    votingResults: [],
    villagerCardsToFalselyShowAsOpen: [],
  };
}

export const useAppStateStore = defineStore(STORAGE_KEY_APP_STATE, {
  state: () => ({
    appState: {
      currentPageTitle: "appName",
      homeRoute: "/",
      searchText: "",
      dayRoleCardView: false,
      showSearchBar: false,
      playerListCircleView: false,
      playerCircles: [] as PlayerCircle[],
      dragPlayers: false,
      playerCircleViewIsActive: false,
      showDeadPlayers: true,
      showGhostWords: true,
    } as AppState,
    isoInstallPopupWasShown: false,
    refreshFunction: (done: () => void) => done(),
  }),
  getters: {
    getAppState(): AppState {
      return this.appState;
    },
    getRefreshFunction(): (done: () => void) => void {
      return this.refreshFunction;
    },
  },
  actions: {
    resetRefreshFunction() {
      this.refreshFunction = (done: () => void) => done();
    },
    setRefreshFunction(refreshFunction: (done: () => void) => void) {
      this.refreshFunction = refreshFunction;
      this.appState.dragPlayers = false;
    },
    reset() {
      this.appState = {
        currentRoute: "",
        lastRoute: "",
        currentPageTitle: "appName",
        dayRoleCardView: false,
        homeRoute: "/",
        searchText: "",
        showSearchBar: false,
        playerListCircleView: false,
        playerCircles: [],
        dragPlayers: false,
        playerCircleViewIsActive: false,
        showDeadPlayers: true,
        showGhostWords: true,
      };
      localStorage.removeItem(STORAGE_KEY_APP_STATE);
    },
    save() {
      localStorage.setItem(
        STORAGE_KEY_APP_STATE,
        JSON.stringify(this.appState)
      );
    },
    initializeAppState() {
      const appState = localStorage.getItem(STORAGE_KEY_APP_STATE);
      if (appState) {
        this.appState = JSON.parse(appState);
      }
    },
  },
});

export const useGhostCardStore = defineStore(STORAGE_KEY_GHOST_CARDS, {
  state: () => ({
    ghostCards: [] as GhostCard[],
  }),
  getters: {
    getDayRoleCards(): GhostCard[] {
      return this.ghostCards;
    },
  },
  actions: {
    addCard(card: GhostCard) {
      this.ghostCards.push(card);
      localStorage.setItem(
        STORAGE_KEY_GHOST_CARDS,
        JSON.stringify(this.ghostCards)
      );
    },
    save() {
      localStorage.setItem(
        STORAGE_KEY_GHOST_CARDS,
        JSON.stringify(this.ghostCards)
      );
    },
    initializeGhostCards() {
      const ghostCards = localStorage.getItem(STORAGE_KEY_GHOST_CARDS);
      if (ghostCards) {
        this.ghostCards = JSON.parse(ghostCards);
      }
    },
  },
});

export const useTabStore = defineStore(STORAGE_KEY_TAB, {
  state: () => ({
    tabs: [] as string[],
    currentTab: "homePlayerYou",
    dontSwipte: false,
  }),
  getters: {
    getTabs(): string[] {
      return this.tabs;
    },
    getCurrentTab(): string {
      return this.currentTab;
    },
  },
  actions: {
    reset() {
      this.tabs = [];
      this.currentTab = "homePlayerYou";
      localStorage.removeItem(STORAGE_KEY_TAB);
      localStorage.removeItem(STORAGE_KEY_TAB + "curr");
    },
    setTabs(tabs: string[]) {
      this.tabs = tabs;
    },
    setCurrentTab(tab: string) {
      this.currentTab = tab;
    },
    switchTab(swipeRight: boolean) {
      const currentIndex = this.tabs.indexOf(this.currentTab);
      if (currentIndex !== -1) {
        let newIndex = swipeRight ? currentIndex - 1 : currentIndex + 1;
        if (newIndex < 0) {
          newIndex = 0;
        } else if (newIndex >= this.tabs.length) {
          newIndex = this.tabs.length - 1;
        }
        if (newIndex !== currentIndex) {
          this.currentTab = this.tabs[newIndex];
        }
      }
    },
    save() {
      localStorage.setItem(
        STORAGE_KEY_TAB + "curr",
        JSON.stringify(this.currentTab)
      );
      localStorage.setItem(STORAGE_KEY_TAB, JSON.stringify(this.tabs));
    },
    initializeTab() {
      const tabs = localStorage.getItem(STORAGE_KEY_TAB);
      if (tabs) {
        this.tabs = JSON.parse(tabs);
      }
      const cur = localStorage.getItem(STORAGE_KEY_TAB + "curr");
      if (cur) {
        this.currentTab = JSON.parse(cur);
      }
    },
  },
});

export const useConnectionStore = defineStore({
  id: "connection",

  state: () => ({
    isConnected: navigator.onLine,
  }),

  actions: {
    monitorConnection() {
      window.addEventListener("online", this.updateStatus);
      window.addEventListener("offline", this.updateStatus);
    },

    unmonitorConnection() {
      window.removeEventListener("online", this.updateStatus);
      window.removeEventListener("offline", this.updateStatus);
    },

    updateStatus() {
      this.isConnected = navigator.onLine;
    },
  },
});
