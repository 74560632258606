import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { setup as setupFirebase } from "@/services/firebase.service";
import Quasar from 'quasar/src/vue-plugin.js';;
import i18n from "./i18n";
import quasarUserOptions from "@/quasar-user-options";
import { pinia } from "@/pinia";
import VueApexCharts from "vue3-apexcharts";
import { useAppSettingsStore } from "@/services/pinia.service";

setupFirebase();

const app = createApp(App);
app
  .use(router)
  .use(Quasar, quasarUserOptions)
  .use(i18n)
  .use(pinia)
  .use(VueApexCharts);

app.mount("#app");

const appSettings = useAppSettingsStore();
appSettings.initializeAppSettings();

i18n.global.locale.value = appSettings.settings.language;
