export enum Aktion {
  KEINE = "KEINE",
  KARTE_ZEIGEN = "KARTE_ZEIGEN",
  FRAKTION_ZEIGEN = "FRAKTION_ZEIGEN",
  ROLLE_ZEIGEN = "ROLLE_ZEIGEN",
  FRAKTION_WECHSELN = "FRAKTION_WECHSELN",
  ALS_ANDERE_ROLLE_ZEIGEN = "ALS_ANDERE_ROLLE_ZEIGEN",
  KARTE_ZUWEISEN = "KARTE_ZUWEISEN",
  NEUE_KARTE_AUS_ALLEN_GEBEN = "NEUE_KARTE_AUS_ALLEN_GEBEN",
  NEUE_KARTE_AUS_UEBRIGEN_GEBEN = "NEUE_KARTE_AUS_UEBRIGEN_GEBEN",
  STIMMRECHT_ANPASSEN = "STIMMRECHT_ANPASSEN",
  MARK_AS_WEREWOLF_TARGET = "MARK_AS_WEREWOLF_TARGET",
  HANG = "HANG",
}
