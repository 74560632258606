import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  useAppStateStore,
  useConnectionStore,
  useTabStore,
  useUserStore,
} from "@/services/pinia.service";
import { Store } from "pinia";
import { MyUser } from "@/models/MyUser";
import { userService } from "@/services/user.service";
import { ref } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/join-game-lobby",
  },
  {
    path: "/create-card",
    name: "create-card",
    component: () => import("@/features/views/EditCardView.vue"),
    meta: {
      requiresAuth: true,
      title: "CreateCard",
    },
    props: () => ({ cardId: "" }),
  },
  {
    path: "/edit-card",
    name: "edit-card",
    component: () => import("@/features/views/EditCardView.vue"),
    meta: {
      requiresAuth: true,
      title: "editCard",
    },
    props: (route) => ({ cardId: route.query.cardId, copy: route.query.copy }),
  },
  {
    path: "/card-deck",
    name: "card-deck",
    component: () => import("@/features/views/CardDeckView.vue"),
    meta: {
      requiresAuth: true,
      title: "CardDeck",
    },
  },
  {
    path: "/app-settings",
    name: "app-settings",
    component: () => import("@/features/views/AppSettingsView.vue"),
    meta: {
      requiresAuth: true,
      title: "AppSettings",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/features/views/ProfileView.vue"),
    meta: {
      requiresAuth: true,
      title: "Profile",
    },
  },
  {
    path: "/game-settings",
    name: "game-settings",
    component: () => import("@/features/views/DefaultGameSettingsView.vue"),
    meta: {
      requiresAuth: true,
      title: "GameSettings",
    },
  },
  {
    path: "/community-cards",
    name: "community-cards",
    component: () => import("@/features/views/CommunityCards.vue"),
    meta: {
      requiresAuth: true,
      title: "CommunityCards",
    },
  },
  {
    path: "/tutorials",
    name: "tutorials",
    component: () => import("@/features/views/TutorialsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Tutorials",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/features/auth/LoginView.vue"),
    meta: { title: "Home" },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/features/auth/RegisterView.vue"),
    meta: { title: "Home" },
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/features/auth/ImpressumView.vue"
      ),
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/features/auth/DatenschutzView.vue"
      ),
  },
  {
    path: "/join-game",
    name: "join-game",
    component: () => import("@/features/views/JoinGameView.vue"),
    meta: {
      requiresAuth: true,

      title: "Home",
    },
    props: (route) => ({ gameId: route.query.gameId }),
  },
  {
    path: "/join-game-lobby",
    name: "join-game-lobby",
    component: () => import("@/features/views/JoinGameLobby.vue"),
    meta: {
      requiresAuth: true,
      title: "JoinGame",
    },
  },
  {
    path: "/create-game",
    name: "create-game",
    component: () => import("@/features/views/CreateGameView.vue"),
    meta: {
      requiresAuth: true,
      title: "CreateGame",
    },
  },
  {
    path: "/playground-offline",
    name: "playground-offline",
    component: () => import("@/features/views/PlaygroundOfflineView.vue"),
    meta: {
      requiresAuth: true,

      title: "PlaygroundOffline",
    },
  },
  {
    path: "/playground-online",
    name: "playground-online",
    component: () => import("@/features/views/PlaygroundOnlineView.vue"),
    meta: {
      requiresAuth: true,

      title: "PlaygroundOnline",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const backWasClicked = ref(false);
const dontListenToBackButton = ref(false);

const handleBackButton = () => {
  if (dontListenToBackButton.value) {
    dontListenToBackButton.value = false;
    return;
  }
  backWasClicked.value = true;
};

window.addEventListener("popstate", handleBackButton);

const getCurrentUser = (
  userStore: Store<
    "user",
    { user: MyUser | null },
    { getUser(): MyUser | null },
    {
      setUser(user: MyUser): void;
      updateUser(updatedUser: MyUser): void;
      removeUser(): void;
      initializeUser(): void;
    }
  >
) => {
  return new Promise((resolve, reject) => {
    const removeListner = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListner();
        resolve(user);
        if (user && !userStore.user) {
          userStore.setUser(userService.mapUser(user));
        }
      },
      reject
    );
  });
};

function getTabNameFromRoute(to: RouteLocationNormalized): string {
  switch (to.name) {
    case "card-deck":
      return "cardDeck";
    case "create-card":
      return "createCardBasic";
    case "edit-card":
      return to.fullPath === "/create-card"
        ? "createCardBasic"
        : "createCardBasic-dont-push";
    case "community-cards":
      return "communityCards";
    case "app-settings":
      return "appSettings";
    case "profile":
      return "profile";
    case "game-settings":
      return "defaultGameSettings";
    case "tutorials":
      return "tutorials";
    case "join-game-lobby":
      return "homePlayerYou";
    case "playground-offline":
      return "homeOwnerGame";
    case "playground-online":
      return "homeOwnerGame";
    default:
      return "";
  }
}

function test() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(1);
    }, 10);
  });
}
router.beforeEach(async (to, from, next) => {
  const path = to.fullPath;
  const appStateStore = useAppStateStore();
  const tabStore = useTabStore();
  const connectionStore = useConnectionStore();
  appStateStore.resetRefreshFunction();
  await test();
  if (backWasClicked.value) {
    backWasClicked.value = false;
    dontListenToBackButton.value = true;
    return next(false);
  }
  if (!connectionStore.isConnected) {
    next();
  }
  if (path.includes("/join-game?gameId=")) {
    appStateStore.appState.deepLinkRoute = path;
    appStateStore.save();
  }
  const userStore = useUserStore();
  const tabName = getTabNameFromRoute(to);
  if (tabName !== "") {
    tabStore.setCurrentTab(tabName);
  }
  if (to.matched.some((record) => record.meta.requiresAuth))
    if (await getCurrentUser(userStore)) {
      next();
    } else {
      if (userStore.user) {
        next();
      } else {
        next({ name: "login" });
      }
    }
  else next();
});

export default router;
