import {
  collection,
  Firestore,
  getDocs,
  query,
  getFirestore,
} from "firebase/firestore";
import { firebaseApp } from "@/services/firebase.service";
import { Prices } from "@/models/Prices";

const db: Firestore = getFirestore(firebaseApp);

export const pricesService = {
  async getPrices(): Promise<Prices> {
    try {
      const usersCollection = collection(db, "prices");
      const q = query(usersCollection);
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw Error("Prices empty");
      } else {
        console.error("Fehler beim Überprüfen des Benutzers:");
        const pricesData: Prices[] = [];
        querySnapshot.forEach((doc) => {
          pricesData.push(doc.data() as Prices);
        });
        return pricesData[0];
      }
    } catch (error) {
      console.error("Fehler beim Überprüfen des Prices:", error);
      throw error;
    }
  },
};
