import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyDAkiDtsUKLuu0iQgy0EE7vgMyW41MfX-A",
  authDomain: "werewolf-local-a470e.firebaseapp.com",
  databaseURL:
    "https://werewolf-local-a470e-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "werewolf-local-a470e",
  storageBucket: "werewolf-local-a470e.appspot.com",
  messagingSenderId: "142824701307",
  appId: "1:142824701307:web:57139f273647ce569e51de",
  measurementId: "G-0GSREEFHC3",
};
export const setup = () => {
  initializeApp(firebaseConfig);
};

export const firebaseApp = initializeApp(firebaseConfig);
