import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { firebaseApp } from "@/services/firebase.service";
import { Karte } from "@/models/Karte";
import { Aktion } from "@/models/enums/Aktion";
import { StirbtBeiAnwendung } from "@/models/enums/StirbtBeiAnwendung";
import { Manipulation } from "@/models/enums/Manipulation";
import { MyUser } from "@/models/MyUser";
import { Player } from "@/models/Player";
import { useI18n } from "vue-i18n";
import { DayRoleCard } from "@/models/DayRoleCard";

const db: Firestore = getFirestore(firebaseApp);
const COMMUNITY_CARDS_PATH = "community-cards";
const CARDS_STORE_PATH = "card-store";
const DAY_ROLE_CARD_STORE_PATH = "day-role-cards";

export enum CardStore {
  COMMUNITY_CARDS_PATH = "community-cards",
  CARDS_STORE_PATH = "card-store",
  DAY_ROLE_CARD_STORE_PATH = "day-role-cards",
}

export const cardService = {
  async getCards(from: CardStore): Promise<Karte[] | DayRoleCard[]> {
    try {
      const cardsCollection = collection(db, from);
      const querySnapshot = await getDocs(cardsCollection);

      return querySnapshot.docs.map((doc) => doc.data() as Karte | DayRoleCard);
    } catch (error) {
      throw new Error("Fehler beim Laden der Karten: " + error);
    }
  },

  async deleteCard(card: Karte | DayRoleCard): Promise<void> {
    try {
      const cardsCollection = collection(db, getPath(card));
      const q = query(cardsCollection, where("id", "==", card.id));
      const querySnapshot = await getDocs(q);

      return querySnapshot.forEach(async (doc) => {
        return await deleteDoc(doc.ref);
      });
    } catch (error) {
      throw new Error("Fehler beim Löschen der Karte: " + error);
    }
  },

  async saveCard(
    card: Karte | DayRoleCard
  ): Promise<void | Karte | DayRoleCard> {
    const addData = async (): Promise<Karte | DayRoleCard> => {
      try {
        const newDocRef = await addDoc(collection(db, getPath(card)), card);
        console.log(
          "Dokument erfolgreich hinzugefügt mit der ID:",
          newDocRef.id
        );
        return card;
      } catch (error) {
        throw new Error("Fehler beim Hinzufügen der Karte: " + error);
      }
    };

    try {
      const cardsCollection = collection(db, getPath(card));
      const q = query(cardsCollection, where("id", "==", card.id));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return await addData();
      } else {
        return await this.updateCard(card);
      }
    } catch (error) {
      console.error("Fehler beim Überprüfen der Karte:", error);
    }
  },

  async updateCard(card: Karte | DayRoleCard): Promise<void> {
    const updateData = async (documentId: string) => {
      try {
        const documentRef = doc(db, getPath(card), documentId);
        const newData = {
          ...card,
        };
        return await updateDoc(documentRef, newData);
      } catch (error) {
        throw new Error("Fehler beim Aktualisieren der Karte: " + error);
      }
    };

    try {
      const cardsCollection = collection(db, getPath(card));
      const q = query(cardsCollection, where("id", "==", card.id));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("Karte nicht gefunden");
      } else {
        querySnapshot.forEach((doc) => {
          updateData(doc.id);
        });
      }
    } catch (error) {
      throw new Error("Fehler beim Aktualisieren der Karte: " + error);
    }
  },

  getCardOfPlayer(player: Player): Karte | DayRoleCard {
    return player.karten[player.karten.length - 1];
  },

  isNightRoleCard(card: Karte | DayRoleCard): card is DayRoleCard {
    return "fraktion" in card;
  },

  isEqual(card1: Karte | DayRoleCard, card2: Karte | DayRoleCard): boolean {
    if (card1.id !== card2.id) {
      return false;
    }
    if (card1.name !== card2.name) {
      return false;
    }
    if (card1.beschreibung !== card2.beschreibung) {
      return false;
    }
    if (this.isNightRoleCard(card1) && this.isNightRoleCard(card2)) {
      const nightRoleCard1 = card1 as Karte;
      const nightRoleCard2 = card2 as Karte;
      if (nightRoleCard1.fraktion !== nightRoleCard2.fraktion) {
        return false;
      }
      if (nightRoleCard1.rating !== nightRoleCard2.rating) {
        return false;
      }
      if (
        nightRoleCard1.nachtsAufrufen !== nightRoleCard2.nachtsAufrufen ||
        nightRoleCard1.nurInDerErstenNachtAufrufen !==
          nightRoleCard2.nurInDerErstenNachtAufrufen ||
        nightRoleCard1.wannNachtsAufrufen !== nightRoleCard2.wannNachtsAufrufen
      ) {
        return false;
      }
    }
    if (
      card1.variableAnzahlExemplare !== card2.variableAnzahlExemplare ||
      card1.exemplare !== card2.exemplare
    ) {
      return false;
    }

    if (!isEqualArrays(card1.aktiveEffekte, card2.aktiveEffekte)) {
      return false;
    } else if (!isEqualArrays(card1.passiveEffekte, card2.passiveEffekte)) {
      return false;
    }
    return true;
  },

  isInCardList(
    card: Karte | DayRoleCard,
    cardList: Karte[] | DayRoleCard[]
  ): boolean {
    return cardList.some((c) => this.isEqual(c, card));
  },

  isCardIdInList(cardId: string, cardList: Karte[] | DayRoleCard[]): boolean {
    return cardList.some((c) => c.id === cardId && c.id !== "");
  },

  getCardById(
    cardId: string,
    cardList: Karte[] | DayRoleCard[]
  ): Karte | DayRoleCard | undefined {
    return cardList.find((c) => c.id === cardId);
  },

  getCardsByName(
    cardName: string,
    cardList: Karte[] | DayRoleCard[]
  ): Karte[] | DayRoleCard[] {
    if (cardName === "") {
      return cardList;
    }
    const result = [];
    const t = useI18n().t;
    for (const card of cardList) {
      if (
        card.name.toLowerCase().includes(cardName.toLowerCase()) ||
        (card.hasI18nKey &&
          t(`cardsI18n.${card.i18nKey}.name`)
            .toLowerCase()
            .includes(cardName.toLowerCase()))
      ) {
        result.push(card);
      }
    }
    return result;
  },

  canSave(
    card: Karte | DayRoleCard,
    cardList: Karte[] | DayRoleCard[]
  ): boolean {
    if (card.hasI18nKey) {
      if (card.i18nKey === "") {
        return false;
      }
    } else {
      if (card.name.length === 0) {
        return false;
      }
      // Check if at least one description field is filled
      if (card.beschreibung.length === 0) {
        return false;
      }
    }

    // Check if the exemplare field is filled
    if (!card.variableAnzahlExemplare && card.exemplare <= 0) {
      return false;
    }

    for (const effect of card.aktiveEffekte) {
      // Check if at least one name field is filled
      if (effect.name.length === 0 && !card.hasI18nKey) {
        return false;
      }
      // Check if the exemplare field is filled
      if (effect.numberOfTargets <= 0) {
        return false;
      }
      // Check if the verknüpfte Karte field is filled
      if (
        [Aktion.ALS_ANDERE_ROLLE_ZEIGEN, Aktion.KARTE_ZUWEISEN].includes(
          effect.aktion
        )
      ) {
        if (!this.isCardIdInList(effect.aktionVerknuepfteKarte, cardList)) {
          return false;
        }
      }
      // Check if the verknüpfte Karte field is filled
      if (
        [StirbtBeiAnwendung.STIRBT_FALLS_KARTE].includes(
          effect.stirbtBeiAnwendung
        )
      ) {
        if (
          !this.isCardIdInList(
            effect.stirbtBeiAnwendungVerknuepfteKarte,
            cardList
          )
        ) {
          return false;
        }
      }
    }

    for (const effect of card.passiveEffekte) {
      // Check if the verknüpfte Karte field is filled
      if (
        [
          Manipulation.ALS_ANDERE_KARTE_ZEIGEN,
          Manipulation.ALS_ANDERE_ROLLE_ZEIGEN,
        ].includes(effect.manipulation)
      ) {
        if (
          !this.isCardIdInList(effect.manipulationVerknuepfteKarte, cardList)
        ) {
          return false;
        }
      }
    }
    return true;
  },

  canEdit(card: Karte | DayRoleCard, user: MyUser | null): boolean {
    return card.id.split("-")[0] === (user?.uid ?? "anonymous");
  },
};

function getPath(card: Karte | DayRoleCard): string {
  return cardService.isNightRoleCard(card)
    ? card.createdByAdmin
      ? CARDS_STORE_PATH
      : COMMUNITY_CARDS_PATH
    : DAY_ROLE_CARD_STORE_PATH;
}

function isEqualArrays<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}
