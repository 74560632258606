import {
  collection,
  Firestore,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { firebaseApp } from "@/services/firebase.service";
import { GhostCard } from "@/models/GhostCard";

const db: Firestore = getFirestore(firebaseApp);

export const ghostCardService = {
  async getCards(): Promise<GhostCard[]> {
    try {
      const cardsCollection = collection(db, "ghost-cards");
      const querySnapshot = await getDocs(cardsCollection);

      return querySnapshot.docs.map((doc) => doc.data() as GhostCard);
    } catch (error) {
      throw new Error("Fehler beim Laden der Geist Karte: " + error);
    }
  },

  isCardIdInList(i18nKey: string, cardList: GhostCard[]): boolean {
    return cardList.some((c) => c.i18nKey === i18nKey);
  },
  getRandomI18nKeyWords(): string[] {
    const result: string[] = [];
    while (result.length < 10) {
      const randomWord =
        allI18nWords[Math.floor(Math.random() * allI18nWords.length)];
      if (!result.includes(randomWord)) {
        result.push(randomWord);
      }
    }
    return result;
  },
};

const allI18nWords = [
  "gross",
  "klein",
  "dick",
  "duenn",
  "lang",
  "kurz",
  "blond",
  "bruennett",
  "schwarzhaarig",
  "lockig",
  "glatt",
  "kraus",
  "blauaeugig",
  "gruenaeugig",
  "braunaeugig",
  "sommersprossig",
  "bartlos",
  "baertig",
  "schnurrbaertig",
  "brillentragend",
  "brillenlos",
  "schmal",
  "breit",
  "jugendlich",
  "reif",
  "elegant",
  "sportlich",
  "gemuetlich",
  "modern",
  "traditionell",
  "charismatisch",
  "sympathisch",
  "ernst",
  "froehlich",
  "verschmitzt",
  "schuechtern",
  "selbstbewusst",
  "natuerlich",
  "geschminkt",
  "ungekaemmt",
  "ordentlich",
  "laessig",
  "schick",
  "leger",
  "extravagant",
  "naturschoen",
  "schlank",
  "muskuloes",
  "zierlich",
  "rot",
  "blau",
  "gruen",
  "gelb",
  "orange",
  "violett",
  "rosa",
  "braun",
  "schwarz",
  "weiss",
  "grau",
  "gold",
  "silber",
  "tuerkis",
  "magenta",
  "kastanienbraun",
  "olivgruen",
  "himmelblau",
  "koralle",
  "limettengruen",
  "beige",
  "hellblau",
  "dunkelblau",
  "hellgruen",
  "dunkelgruen",
  "hellbraun",
  "dunkelbraun",
  "hellgrau",
  "dunkelgrau",
  "zartrosa",
  "halskette",
  "armband",
  "ohrringe",
  "ring",
  "anhaenger",
  "uhr",
  "brosche",
  "haarspange",
  "manschettenknoepfe",
  "fusskette",
  "null",
  "eins",
  "zwei",
  "drei",
  "vier",
  "fuenf",
  "sechs",
  "sieben",
  "acht",
  "neun",
  "zehn",
  "elf",
  "zwoelf",
  "dreizehn",
  "vierzehn",
  "fuenfzehn",
  "sechzehn",
  "siebzehn",
  "achtzehn",
  "neunzehn",
  "zwanzig",
  "einundzwanzig",
  "zweiundzwanzig",
  "dreiundzwanzig",
  "vierundzwanzig",
  "fuenfundzwanzig",
  "sechsundzwanzig",
  "siebenundzwanzig",
  "achtundzwanzig",
  "neunundzwanzig",
  "dreissig",
  "einunddreissig",
  "zweiunddreissig",
  "dreiunddreissig",
  "vierunddreissig",
  "fuenfunddreissig",
  "sechsunddreissig",
  "siebenunddreissig",
  "achtunddreissig",
  "neununddreissig",
  "vierzig",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "nebelverhangen",
  "duester",
  "schaurig",
  "geisterhaft",
  "verlassen",
  "gespenstisch",
  "unheimlich",
  "verwittert",
  "stille",
  "dunkel",
  "schattenhaft",
  "kalt",
  "mystisch",
  "gruselig",
  "abgelegen",
  "gespensterhaft",
  "schleierhaft",
  "raetselhaft",
  "spukend",
  "einsam",
  "verfallen",
  "geheimnisvoll",
  "nachtumhuellt",
  "verwunschen",
  "schrecklich",
  "atmosphaerisch",
  "gaensehaut",
  "trueb",
  "geistervoll",
  "erschreckend",
  "pizza",
  "hamburger",
  "sushi",
  "spaghetti",
  "currywurst",
  "croissant",
  "sauerbraten",
  "paella",
  "tacos",
  "curry",
  "kimchi",
];
