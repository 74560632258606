import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/Notify.js';;
export default {
  config: {
    notify: {
      position: "bottom",
      timeout: 2000,
    },
    tooltip: {
      anchor: "top middle",
      self: "bottom middle",
    },
    checkbox: {
      leftLabel: true,
    },
    pullToRefresh: {
      color: "red",
      bgColor: "black",
      icon: "autorenew",
    },
  },
  plugins: { Notify },
};
